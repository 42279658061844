/* 
FileName:index.js
purpose:To se all the archive data
Developers:Naveen Kumar Gade[NKG],Satya Sidda[SS]

 */
import React, { useEffect, useReducer, useState } from 'react';
import TopNavWithOutProject from '../Utility/TopNav/topnav';
// import { MDBTable } from 'mdbreact';
import { useSelector } from 'react-redux';
import { tasksReducer, initialState } from './tasksReducer';
import RootLoader from '../../Common/Loader/RootLoader';
import Moment from 'moment';
import MainTaskInfo from '../../Common/TasksModals/mainTaskInfo';
import { getSubStringId } from '../../Common/SubStringConvert';
import { getToken } from '../../Common/LocalStorage';
import ChatBox from "../../Common/ChatMainTask";
import TaskInfo from "../UserDashboard/taskInfo";
import { useWebProperties } from '../../Common/webProperties';
import MainTaskVerify from '../../Common/TaskVerify/mainTaskVerify';
import { getAllUserStoriesMessages } from './Services/getAllUserStoriesMessages';
import { getUserStoriesBySprint } from './Services/getUserStoriesBySprint';
import { isMobile } from 'react-device-detect';
import ModifyMainTask from '../../Common/TasksModals/modifyMainTask';

import { Link } from 'react-router-dom';
export default function CompletedUserStories() {


    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(tasksReducer, initialState)
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()
    const [searchWord, setSearchWord] = useState('')
    const [chatOpen, setChatOpen] = useState(false);
    const webProperties = useSelector(state => state.landingReducer.webProperties)

    const { CHAT, COMMENTS, VERIFY, SCRUM_MASTER, ACTION_ICON, PRODUCT_OWNER, role_array,REASSIGN ,VIEW_DETAILS} = useWebProperties();

    useEffect(() => {
        getUserStoriesBySprint(dispatch, getUser.user, getToken("Archive_sprint_id"))
        getAllUserStoriesMessages(dispatch, getUser.user);
        // eslint-disable-next-line
    }, []);
    const handleOpen = (action, index, sno) => {
        var info
        setOpen({ status: true, index: index, action: action });
        if (action === "unassigned_taskInfo") {
            var view_status1 = "taskInfo"
            info = {
                view: view_status1,
                projectName: index.projectitle,
                moduleName: index.moduletitle,
                main_task_id:index.main_task_id || '0',
             
                title: index.tasktitle,
                description: index.taskdescription,
                taskId: index.taskid,
                targetDate: index.targettime,
                timeLeft: index.timeLeft,
                extraHours: index.extraHours,
                status: index.completeStatus,
                createdDate: index.assignedon,
                taskProgress: index.taskStatus,
                storyPoints: index.storyPoints,
                acceptanceCriteria: index.acceptanceCriteria,
                assignedTo: index.assignto,
                assignedBy: index.assignby,
                completedDate: index.modifiedDate,
                completedStatus: index.completeStatus,
                us_id: index.us_id
            }
            
        }
        else if (action === "commentInfo") {
            info = {
                us_id: index.us_id,
                title: index.tasktitle,
                id: index.taskid,
            }
        }
        else if (action === "taskInfo" || action === "commentInfo") {
            var view_status = action;
            info = {
                view: view_status,
                projectName: index.projectitle,
                moduleName: index.moduletitle,
                main_task_id:index.main_task_id || '0',
             
                title: index.tasktitle,
                description: index.taskdescription,
                taskId: index.taskid,
                targetDate: index.targettime,
                timeLeft: index.timeLeft,
                extraHours: index.extraHours,
                status: index.completeStatus,
                createdDate: index.assignedon,
                taskProgress: index.taskStatus,
                storyPoints: index.storyPoints,
                acceptanceCriteria: index.acceptanceCriteria,
                assignedTo: index.assignto,
                assignedBy: index.assignby,
                completedDate: index.modifiedDate,
                completedStatus: index.completeStatus,
                us_id: index.us_id
            };
        }
        else if (action === "Verify") {
            info = {
                us_id: index.us_id,
                mainTaskId: index.taskid,
                title: index.tasktitle,
                description: index.taskdescription,
                action: action,
                moduleId: index.moduletitle,
            }
        }
        else if (action === "reassign") {
            info = {
                us_id: index.id,
                projectName: index.projectitle,
                moduleName: index.moduletitle,
                 moduleId: index.taskdescription,
                ideano: index.ideano,
                id:index.assignedTo,
                targetDate:index.targetDate,
                title: index.tasktitle,
                description: index.taskdescription,
                taskId: index.taskid,
                acceptanceCriteria: index.acceptanceCriteria,
                storyPoints: index.storyPoints,
                // userSelected: index.modifiedby,
               // userSelected:index.assignbyId,
                // assignbyId:index.modifiedby,
                userDetails:index.assignto,
            //    assignBy:index.assignby,
              //  assignedTo: index.assignto,
              assignedTo: index.assignto,
              assignedBy: index.assignby,
                priorityLevel: index.priorityLevel,
                sprintTargetDate: index.sprint_targetDate,
                action: action,
               // targetDate: index.targettime,
                currenttime: index.currenttime,
                // currentDate: index.currentDate,
                activeStatus: index.activeStatus,
                backlogs: index.backlogs,
                device_id: index.device_id,
                player_id: index.player_id,
                status: index.completeStatus,
            }        
        }
        else {
            info = { action: action, id: state.currentTasks[index].taskid, sno: sno }
        }
        setCardInfo(info);
    };
    const handleClose = () => {

        setOpen({ status: false, index: 0 });
        getUserStoriesBySprint(dispatch, getUser.user, getToken("Archive_sprint_id"))
        getAllUserStoriesMessages(dispatch, getUser.user);
        


    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
    }

    const handleOpenChat = (action, id, sno) => {
        setChatOpen(true);
        // updateChat(sno,dispatch,getUser.user)
        var info = { action: action, id: id, sno: sno };
        setCardInfo(info);
    };

    const handleChatClose = () => {
        setChatOpen(false);
        getAllUserStoriesMessages(dispatch, getUser.user);
    };

    const getMessagesCount = (id, msg, empId) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })

        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }}
                                onClick={(event) => handleOpenChat("maintask", id, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold" }}>{msgCount.length>9?"9+":msgCount.length}</span>
                        </div>
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }}
                                onClick={(event) => handleOpenChat("maintask", id, msgCount)} />
                        </div>
                }
            </i>
        )
    }
    const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);

    return (
        <div className="container-scroller">
            <TopNavWithOutProject />

            <div className="mt-2">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div>
                                <h2 className="card-title" style={{ overflowWrap: "break-word", color: 'blue', backgroundColor: 'transparent' }}> {getToken("Archive_sprint_title").concat('   [', Moment(getToken("Archive_sprint_startDate")).format('MM.DD.YYYY'), '-', Moment(getToken("Archive_sprint_targetDate")).format('MM.DD.YYYY'), ']')}</h2>
                            </div>
                        
                            <div className="d-flex justify-content-between mb-1 align-items-center justify-content-center">
                            <div>
                            <Link to={{ pathname:'/Archive'}} className=''>
                               <button style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b', color: 'white' }} >Back</button>
                                </Link>
                               
                            </div>
                                <div class="input-group-prepend" >
                                    <span class="text" style={{ color: 'black', paddingRight:5,paddingLeft:10,marginTop:'5px' }}>Search</span>
                            
                                <input type="text" className ="form-control form-control-sm" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black' }}
                                    onChange={(event) => setSearchWord(event.target.value)}
                                />
                                </div>

                            </div>

                            {state.isLoading ? <RootLoader /> :
                                <div className='arc overflow-auto' style={{ maxHeight: '600px',minHeight:"600px" }}>
                                  
                                  {state.completedUserStories.length > 0 ? state.completedUserStories.filter((val) => {
    if (searchWord === ""&&(val.calendar_id===null||val.calendar_id===undefined)) {
        return true;
    } else if ((`${getSubStringId(getUser.user.corp, 3)}-${val.us_id}-${val.tasktitle}`).toLowerCase().includes(searchWord.toLowerCase())&&(val.calendar_id===null||val.calendar_id===undefined)) {
        return true;
    }
    return false;
}).map((tasks, index) => {
    return (
        <div className="col-12" key={tasks.taskid}>
            <div className="card col-12">
                <div className="container-fluid col-12 row" style={{paddingRight:"0px"}}>
                    <div className="d-flex col-12" style={{ padding: 0,...(isMobile ? { justifyContent: 'space-between' } : {}) }}>
                        <div className="d-flex pt-2 " style={{ width: '860px' }}>
                            <b style={{ cursor: 'pointer', paddingTop: 10, fontSize: '12px' }} onClick={(event) => handleOpen("unassigned_taskInfo", tasks)}><del> {getSubStringId(getUser.user.corp, 3)}{'-'}{tasks.us_id}{'-'}{tasks.tasktitle}</del></b>
                            <div>{tasks.completeStatus === 'verified' ? <p style={{ backgroundColor: 'green', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: isMobile?'':'100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom" title={'Verified by ' + tasks.modifiedby}>Verified</p> : null}</div>
                        </div>
                        <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '40px', padding: "0", display: isMobile? "none" : {} }} >
                            {
                                getMessagesCount(tasks.us_id, state.allMessages, getUser.user.empId)
                            }
                        </button>
                        <button style={{
                            border: "0",
                            width: "8px",
                            height: "30px",
                            backgroundColor: "transparent",
                            marginLeft: "10px",
                            display: isMobile? "none" : {},
                        }}
                            type="button"
                            onClick={() =>
                                handleOpen(
                                    "commentInfo", tasks
                                )
                            }
                        >
                            <img
                                src="images/common/comments.svg"
                                title={COMMENTS}
                                alt="logo"
                                style={{ width: "20px", height: "25px", marginLeft: "-17px" }}
                            />
                        </button>
                        <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, marginRight: 5, padding: '5px' }}>
                            <a href="/#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0', display: isMobile? "none" : {} }} />
                            </a>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", tasks)}>{VIEW_DETAILS}</button>
                                {(tasks.completeStatus === 'completed' && accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "orange", color: 'white' }} onClick={(event) => handleOpen("reassign",state.completedUserStories[index])}>{REASSIGN}</button> : null}
                                {(tasks.completeStatus === 'completed' && tasks.completeStatus !== 'verified' && accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#6BC2D3", color: 'white' }} onClick={(event) => handleOpen("Verify",tasks)}>{VERIFY}</button> : null}
                            </div>
                        </div>
                        {
                            isMobile &&
                            <div style={{display:'flex', flexDirection:'row',marginLeft:"20px"}}>
                                <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '30px', padding: "0", marginBottom:'auto', marginTop:'5px' }} >
                                    {
                                        getMessagesCount(tasks.us_id, state.allMessages, getUser.user.empId)
                                    }
                                </button>
                                <button style={{
                                    border: "0",
                                    width: "8px",
                                    height: "30px",
                                    backgroundColor: "transparent",
                                    marginLeft: "5px",
                                }}
                                    type="button"
                                    onClick={() =>
                                        handleOpen(
                                            "commentInfo", tasks
                                        )
                                    }
                                >
                                    <img
                                        src="images/common/comments.svg"
                                        title={COMMENTS}
                                        alt="logo"
                                        style={{ width: "20px", height: "25px", marginLeft: "-17px" }}
                                    />
                                </button>
                                <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, padding: '5px' }}>
                                    <a href="/#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                        <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0' }} />
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                        <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", tasks)}>{VIEW_DETAILS}</button>
                                        {(tasks.completeStatus === 'completed' && tasks.completeStatus !== 'verified' && accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#6BC2D3", color: 'white' }} onClick={(event) => handleOpen("Verify",tasks)}>{VERIFY}</button> : null}
                                        {(tasks.completeStatus === 'completed' && accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "orange", color: 'white' }} onClick={(event) => handleOpen("reassign",state.completedUserStories[index])}>{REASSIGN}</button> : null}  
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}) : null}

                                    {chatOpen ? (
                                        <ChatBox
                                            open={chatOpen}
                                            handleClose={handleChatClose}
                                            data={cardInfo}
                                            handleModalClose={handleChatClose}
                                        />
                                    ) : null}
                                    {
                                        open.action === "unassigned_taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                      {
                                        open.action === "taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        (open.action === "Verify") ? <MainTaskVerify open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                         {
                                        (open.action === "reassign") ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                      {/* {
                                        (open.action === "modify" || open.action === "reassign") ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    } */}


                                    {open.action === "commentInfo" ? (
                                        <TaskInfo
                                            open={open.status}
                                            handleClose={handleClose}
                                            data={cardInfo}
                                            handleModalClose={handleModalClose}
                                        />
                                    ) : null}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}