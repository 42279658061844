import React, { useEffect, useState } from 'react'
import ReportsAPI from '../../Common/AgileNetwork/ReportsAPI';
import { DateRangePicker } from 'react-date-range';
import { startOfWeek, endOfWeek } from 'date-fns';
import AdminTopNav from '../Utility/TopNav';
import AdminSideBar from '../Utility/SideNav';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserProjectInfo from './userProjectInfo';
import RootLoader from '../../Common/Loader/RootLoader';
import { useWebProperties } from '../../Common/webProperties';
import { getDecimalHours } from '../../Common/convertDecimalHoursToTime';
import moment from 'moment';
import Select from 'react-select'
import API from '../../Common/Network/API';
import { removeDuplicatesFromString } from '../../Common/commonUsage';
//import { isMobile } from 'react-device-detect';
import UserCosting from './UserCosting';
export default function AdminReports() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [open, setOpen] = useState({ status: false, index: 0 })

    const [info, setInfo] = useState();
    const [loader, setLoader] = useState(false);
    const { SELECT_DATE_RANGE, ASSIGNED_STORY_POINTS, COMPLETED, PENDING, NO_OF_PROJECTS_INVOLVED,NO_OF_ACCOUNTS,
        DATE, EMPLOYEE_NAME, ALL_EMPLOYEE_REPORTS, TOTAL_HOURS,
        TOTAL_USER_STORIES, USER_EFFICIENCY_HOURS, USER_EFFICIENCY_STORY_POINTS } = useWebProperties();
        const [activeComponent, setActiveComponent] = useState(true);

    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
    }
    // const [state1, dispatch1] = useReducer(landingReducer, initialState)
    const properties = useSelector(state => state.landingReducer.properties)

    const [filter, setFilter] = useState([
        {
            startDate: startOfWeek(new Date(), { weekStartsOn: 0 }),
            endDate: endOfWeek(new Date(), { weekEndsOn: 7 }),
            key: 'selection'
        }
    ])
    // Functions to handle button clicks
  const showUserReport = () => setActiveComponent(true);
  const showUserCosting = () => setActiveComponent(false);

    // const exportPDF = () => {
    //     const doc = new jsPDF()
    //     doc.autoTable({ html: '#adminReports' })
    //     doc.save('table.pdf')
    // }
    const [result, setResult] = useState([])
    const getUser = useSelector(state => state.auth)
    const [filterData, setFilterData] = useState([])
    const [searchFilterData, setSearchFilterData] = useState([]);
    const [groupDetails, setGroupDetails] = useState([])
    const [selectedGroupDetails, setSelectedGroupDetails] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    useEffect(() => {
        getAdminGroupDetails(getUser.user)
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        const sortData = (data) => {
            return data.slice().sort((a, b) => {
                if (sortColumn === 'name') {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    return sortDirection === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
                } else if (['projects_involved', 'utilization_based_on_hours', 'utilization_based_on_task', 'user_story_count', 'pending', 'completed', 'estimated', 'working_hours','no_of_accounts'].includes(sortColumn)) {
                    const a1 = parseFloat(a.data[0][sortColumn]);
                    const b1 = parseFloat(b.data[0][sortColumn]);
                    return sortDirection === 'asc' ? a1 - b1 : b1 - a1;
                }
                return 0;
            });
        };
        if (searchQuery.length > 0) {
            setSearchFilterData(prevData => sortData(prevData));
        } else {
            setFilterData(prevData => sortData(prevData));
        }
        // eslint-disable-next-line
    }, [sortColumn, sortDirection]);

    const filterGroup = (data, groupDetails) => {
        let emailArray = groupDetails.emails.split(',')
        return data.filter(obj => emailArray.includes(obj.email))
    }



    const searchFilter = (data, searchData) => {
        const filteredData = data.filter(item =>
            item.name.toLowerCase().includes(searchData.toLowerCase()) ||
            item.data[0].projects_involved.toString().toLowerCase().includes(searchData.toLowerCase()) ||
            (item.data[0].working_hours !== null && getDecimalHours(item.data[0].working_hours).toString().toLowerCase().includes(searchData.toLowerCase())) ||
            (item.data[0].utilization_based_on_hours !== null && item.data[0].utilization_based_on_hours.toString().toLowerCase().includes(searchData.toLowerCase())) ||
            (item.data[0].utilization_based_on_task !== null && item.data[0].utilization_based_on_task.toString().toLowerCase().includes(searchData.toLowerCase())) ||
            (item.data[0].estimated !== null && parseInt(item.data[0].estimated).toString().toLowerCase().includes(searchData.toLowerCase())) ||
            (item.data[0].user_story_count !== null && item.data[0].user_story_count.toString().toLowerCase().includes(searchData.toLowerCase())) ||
            (item.data[0].pending !== null && item.data[0].pending.toString().toLowerCase().includes(searchData.toLowerCase())) ||
            (item.data[0].completed !== null && item.data[0].completed.toString().toLowerCase().includes(searchData.toLowerCase()))
        );
        return filteredData;
    }

    useEffect(() => {
        if (selectedGroupDetails.value !== 'All' && selectedGroupDetails.length !== 0) {
            let filteredData = filterGroup(result, selectedGroupDetails);
            setFilterData(filteredData);
            if (searchQuery.length > 0) setSearchFilterData(searchFilter(filteredData, searchQuery));

        }
        else {
            setFilterData(result);
            if (searchQuery.length > 0) setSearchFilterData(searchFilter(result, searchQuery));
        }
        // eslint-disable-next-line
    }, [result]);

    async function getAdminGroupDetails(getUser) {
        try {
            var response = await API.post("group_chat.php", {
                action: 'getAdminGroupDetails',
                created_by: getUser.userName,
                projectId: getUser.projectId,
                "userType": getUser.role,
                "empId": getUser.empId,
            }, {}, false);
            if (response.status === 'True') {
                setGroupDetails(response.data);
            }
            else {
                setGroupDetails([])
            }
        } catch (error) {
            console.log('error', error.message);
        }
    }
    const [, extension] = (getUser.user.userName).split('@')
    async function Network(api, state, filter) {
        setResult([])
        setLoader(true)
        try {
            const response = await ReportsAPI.post(`/${api}`, 
                { filter,account_id:extension === 'agile24x7.com' ? "" : getUser.user.account_id }, {}, false)
            var employee = [];
            extension === 'agile24x7.com'
                ?
                response.map((x) => {
                    return ((x.email && (x.emp_id !== getUser.user.empId)) ? employee.push(x) : null);
                })
                :
                response.map((x) => {
                    return (((x.emp_id !== getUser.user.empId)) ? employee.push(x) : null);
                })
            await state(employee)
            setLoader(false)
        }
        catch (error) {
            setLoader(false)
            setMessage(error.message)
            console.log('error', error.message)
        }
    }
    // useEffect(()=>{
    //     getProps(state1,dispatch1);
    //     // eslint-disable-next-line
    // },[])
    const getAllCount = () => {
        // eslint-disable-next-line 
        handleClose()
        let dateFilter = [{
            "startDate": moment(filter[0].startDate).format("YYYY-MM-DD"),
            "endDate": moment(filter[0].endDate).format("YYYY-MM-DD"),
            "key": "selection"
        }]
        Network('getAllCount', setResult, dateFilter)
    }

    useEffect(() => {
        getAllCount()
        // eslint-disable-next-line
    }, [])

    const [message, setMessage] = useState("")


    // useEffect(() => {
    //     if (filterData.length > 0) {
    //         $(document).ready(function () {
    //             window.$('#example').DataTable({
    //                 destroy: true,
    //                 dom: 'Bfrtip',
    //                 aLengthMenu: [
    //                     [25, 50, 100, 200, -1],
    //                     [25, 50, 100, 200, "All"]
    //                 ],
    //                 iDisplayLength: -1,
    //                 buttons: [
    //                     { extend: 'excelHtml5', text: 'Export' }
    //                 ]
    //             })
    //         })
    //     }
    // }, [filterData])

    const handleOpen = (index, action, task_status, column_name) => {
        setOpen({ status: true, index: index, action: action });
        var info = {};
        let dateFilter = [{
            "startDate": moment(filter[0].startDate).format("YYYY-MM-DD"),
            "endDate": moment(filter[0].endDate).format("YYYY-MM-DD"),
            "key": "selection"
        }]
        if (action === "getAllStories") {
            info = {
                user_id: searchQuery.length === 0 ? filterData[index].emp_id : searchFilterData[index].emp_id,
                action: 'getAllStories',
                name: searchQuery.length === 0 ? filterData[index].name : searchFilterData[index].name,
                dateFilter,
                task_status,
                column_name
            }
        }
        if (action === "getOnlyStories") {
            info = {
                user_id: searchQuery.length === 0 ? filterData[index].emp_id : searchFilterData[index].emp_id,
                action: 'getOnlyStories',
                name: searchQuery.length === 0 ? filterData[index].name : searchFilterData[index].name,
                dateFilter,
                task_status,
                column_name
            }
        }
        if (action === "getUtilizationStories") {
            info = {
                user_id: searchQuery.length === 0 ? filterData[index].emp_id : searchFilterData[index].emp_id,
                action: 'getUtilizationStories',
                name: searchQuery.length === 0 ? filterData[index].name : searchFilterData[index].name,
                dateFilter,
                task_status,
                column_name
            }
        }
        if (action === "getUtilizationHours") {
            info = {
                user_id: searchQuery.length === 0 ? filterData[index].emp_id : searchFilterData[index].emp_id,
                action: 'getUtilizationHours',
                name: searchQuery.length === 0 ? filterData[index].name : searchFilterData[index].name,
                dateFilter,
                task_status,
                column_name
            }
        }
        if (action === "getUserTimeSheets") {
            info = {
                user_id: searchQuery.length === 0 ? filterData[index].emp_id : searchFilterData[index].emp_id,
                action: 'getUserTimeSheets',
                name: searchQuery.length === 0 ? filterData[index].name : searchFilterData[index].name,
                dateFilter,
                column_name,
                from: 'admin',
                u_id: searchQuery.length === 0 ? filterData[index].u_id : searchFilterData[index].u_id,
                player_id: searchQuery.length === 0 ? filterData[index].player_id : searchFilterData[index].player_id,
                device_id: searchQuery.length === 0 ? filterData[index].device_id : searchFilterData[index].device_id,
                email: searchQuery.length === 0 ? filterData[index].email : searchFilterData[index].email,

            }
        }
        if (action === "getUserProjects") {
            info = {
                user_id: searchQuery.length === 0 ? filterData[index].emp_id : searchFilterData[index].emp_id,
                action: 'getUserProjects',
                name: searchQuery.length === 0 ? filterData[index].name : searchFilterData[index].name,
                account_id:extension === 'agile24x7.com' ? "" : getUser.user.account_id,
                dateFilter,
                column_name
            }
        }
        if (action === "getUserAccounts") {
            info = {
                user_id: searchQuery.length === 0 ? filterData[index].emp_id : searchFilterData[index].emp_id,
                action: 'getUserAccounts',
                name: searchQuery.length === 0 ? filterData[index].name : searchFilterData[index].name,
                account_id:extension === 'agile24x7.com' ? "" : getUser.user.account_id,
                dateFilter,
                column_name
            }
        }
        setInfo(info);
    };

    const handleSelectedGroups = (selectedOption) => {
        //onsole.log(selectedOption);
        if (selectedOption.value === 'All') {
            setFilterData(result)
        } else {
            setFilterData(filterGroup(result, selectedOption))
        }
        setSearchQuery('');

    }

    // const handleSelectedGroups = (selectedOption) => {
    //     //onsole.log(selectedOption);
    //     if (selectedOption.value === 'All') {
    //         setFilterData(result)
    //     } else {
    //         let emailArray = selectedOption.emails.split(',')
    //         setFilterData(result.filter(obj => emailArray.includes(obj.email)))
    //     }
    //     setSearchQuery('');

    // }

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSearchFilterData(searchFilter(filterData, event.target.value));
    };

    // const sortedData = filterData.sort((a, b) => {
    //     const aValue = a.data[0][sortColumn];
    //     const bValue = b.data[0][sortColumn];
    //     if (sortDirection === 'asc') {
    //         return aValue > bValue ? 1 : -1;
    //     } else {
    //         return aValue < bValue ? 1 : -1;
    //     }
    // });



    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const getSortIcon = (column) => {
        return (
            <>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                </svg>
            </>
        );
    };
const handleComponent=()=>{
    setActiveComponent(!false)
}

    return (
        <div className="container-scroller">
            <AdminTopNav />
            <div className="container-fluid page-body-wrapper">
                <AdminSideBar />
                <div className="main-panel">
                    {activeComponent ? 
 <div className="mt-2">
 <div className="col-lg-12 grid-margin stretch-card">
     <div className="card">
         <div className="card-body">
             <h4 className="card-title">{ALL_EMPLOYEE_REPORTS}p</h4>
             <div className="d-flex justify-content-between row">
                 <div className="d-flex" >
                     {
                         <button className="btn btn-success p-2 border" style={{ marginLeft: 10 }} onClick={handleShow}>{SELECT_DATE_RANGE}</button>
                     }
                     {
                         <div style={{ marginTop: 5 }}>
                             <text style={{ fontSize: 14, fontWeight: 'bold', paddingLeft: 10 }} className=' text-success'> {DATE}: {(moment(filter[0].startDate).format('ll'))} - {(moment(filter[0].endDate).format('ll'))} </text>

                         </div>
                     }
                 </div>
                 <div className="d-flex col-lg-4 col-sm-6" >
                     <label className='mr-2 mb-0 mt-2' htmlFor='groupSelect'>Group:</label>
                     <Select
                         className="form-control"
                         placeholder="Select Group"
                         options={[
                             { 'value': 'All', 'label': 'All' },
                             ...(groupDetails?.map(group => {
                                 return {
                                     'value': group.id,
                                     'label': `${group.group_name}-[${removeDuplicatesFromString(group.members_name)}]`,
                                     'emails': group.members_email
                                 };
                             }))
                         ]}
                         onChange={(selectedOption) => {
                             handleSelectedGroups(selectedOption)
                             setSelectedGroupDetails(selectedOption)
                         }}
                     />
                 </div>
                 {/* <CSVLink className="downloadbtn btn btn-primary " filename={`AllReports-${new Date().getFullYear()}.csv`} data={csvData}>Export to CSV</CSVLink> */}
                 
               
             </div>
             <div className="d-flex align-items-center justify-content-between mt-1">
<div className="me-auto">
<div className="d-flex align-items-center mt-1">
<button
 className={`btn me-2 ${activeComponent ? 'btn-success' : 'text-success border border-success'}`}
onClick={showUserReport}
>
User Report
</button>
<button
 className={`btn ${!activeComponent  ? 'btn-success' : 'text-success border border-success'}`}
onClick={showUserCosting}
>
User Costing
</button>
</div>

</div>
<div>
<label className="me-2 mb-0" htmlFor="searchInput">Search:</label>
<input
type="text"
className="form-control d-inline-block"
style={{ height: '30px', width: '200px' }}
aria-label="Search"
aria-describedby="basic-addon2"
onChange={handleSearch}
value={searchQuery}
/>
</div>
</div>


             <div className="col-12 " >
                 <Modal style={{ textAlign: 'center' }} size="xl" show={show} onHide={handleClose}>
                     <Modal.Header closeButton>
                     </Modal.Header>
                     <Modal.Body>
                         <DateRangePicker
                             onChange={item => setFilter([item.selection])}
                             showSelectionPreview={true}
                             moveRangeOnFirstSelection={false}
                             months={2}
                             ranges={filter}
                             direction="horizontal"
                         />
                     </Modal.Body>
                     <Modal.Footer>
                         <button className="btn btn-success" type="button" onClick={() => {
                             getAllCount()

                         }}>Search</button>
                     </Modal.Footer>
                 </Modal>

             </div>
         </div>
         {
             message && (<><br /><br /><span style={{ color: 'red', marginLeft: '10%' }}>{message}</span></>)
         }
         {/* <div style={{ textAlign: 'end', padding: '20px' }} onClick={() => exportPDF()}>Export</div> */}
         <div className='p-2'>
             <div className='pt-2' />
             {result.length !== 0 ?
                 <div className="table-responsive">
                     <table
                         search="true"
                         id="example" className="table table-striped table-bordered"
                         data-pagination="true"
                     >
                         <thead style={{ backgroundColor: '#F4FAF7' }}>
                             <tr>
                                 <th className="text-capitalize" onClick={() => handleSort('name')}>{properties?.EMPLOYEE_NAME || EMPLOYEE_NAME} {getSortIcon('name')}</th>
                                 <th onClick={() => handleSort('projects_involved')}>{properties?.NO_OF_PROJECTS_INVOLVED || NO_OF_PROJECTS_INVOLVED} {getSortIcon('no_of_projects_involved')}</th>
                                 {extension === 'agile24x7.com' && <th onClick={() => handleSort('no_of_accounts')}>{properties?.NO_OF_ACCOUNTS || NO_OF_ACCOUNTS} {getSortIcon('no_of_accounts')}</th>}
                                 <th onClick={() => handleSort('working_hours')}>{properties?.TOTAL_HOURS || TOTAL_HOURS} {getSortIcon('total_hours')}</th>
                                 <th onClick={() => handleSort('utilization_based_on_hours')}>{properties?.USER_EFFICIENCY_HOURS || USER_EFFICIENCY_HOURS}{" "}% {getSortIcon('user_efficiency_hours')}</th>
                                 <th onClick={() => handleSort('utilization_based_on_task')}>{properties?.USER_EFFICIENCY_STORY_POINTS || USER_EFFICIENCY_STORY_POINTS}{" "}% {getSortIcon('user_efficiency_story_points')}</th>
                                 <th onClick={() => handleSort('estimated')}>{properties?.ASSIGNED_STORY_POINTS || ASSIGNED_STORY_POINTS} {getSortIcon('assigned_story_points')}</th>
                                 <th onClick={() => handleSort('user_story_count')}>{properties?.TOTAL_USER_STORIES || TOTAL_USER_STORIES} {getSortIcon('total_user_stories')}</th>
                                 <th onClick={() => handleSort('pending')}>{properties?.PENDING || PENDING} {getSortIcon('pending')}</th>
                                 <th onClick={() => handleSort('completed')}>{properties?.COMPLETED || COMPLETED} {getSortIcon('completed')}</th>
                             </tr>
                         </thead>
                         <tbody>
                             {
                                 (searchQuery.length === 0) ? filterData.map((x, index) => {
                                     return (
                                         <tr key={index}>
                                             <td style={{ height: 40, fontWeight: 'bold', color: 'green', cursor: 'pointer' }}><Link style={{ color: 'green' }} to={{ pathname: '/admin/indReports', state: { emp_id: x.emp_id, name: x.name, filter: filter, projects_involved: x.data[0].projects_involved } }} >{x.name}</Link></td>
                                             <td style={{ height: 40 }} onClick={() => handleOpen(index, "getUserProjects", "", properties?.NO_OF_PROJECTS_INVOLVED || NO_OF_PROJECTS_INVOLVED)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].projects_involved}</p></td>
                                             {extension === 'agile24x7.com' && <td style={{ height: 40 }} onClick={() => handleOpen(index, "getUserAccounts", "", properties?.NO_OF_ACCOUNTS || NO_OF_ACCOUNTS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].no_of_accounts}</p></td>}
                                             <td onClick={() => handleOpen(index, "getUserTimeSheets", "", properties?.TOTAL_HOURS || TOTAL_HOURS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].working_hours !== null ? getDecimalHours(x.data[0].working_hours) : 0}</p></td>
                                             {x.data[0].utilization_based_on_hours !== null ? <td style={{ height: 40 }} onClick={() => handleOpen(index, "getUtilizationHours", "all", properties?.USER_EFFICIENCY_HOURS || USER_EFFICIENCY_HOURS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].utilization_based_on_hours}</p></td> : <td style={{ height: 40 }}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>0</p></td>}
                                             {x.data[0].utilization_based_on_task !== null ? <td style={{ height: 40 }} onClick={() => handleOpen(index, "getUtilizationStories", "all", properties?.USER_EFFICIENCY_STORY_POINTS || USER_EFFICIENCY_STORY_POINTS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].utilization_based_on_task}</p></td> : <td style={{ height: 40 }}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>0</p></td>}
                                             <td onClick={() => handleOpen(index, "getOnlyStories", "all", properties?.ASSIGNED_STORY_POINTS || ASSIGNED_STORY_POINTS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].estimated !== null ? parseInt(x.data[0].estimated) : 0}</p></td>
                                             <td onClick={() => handleOpen(index, "getAllStories", "all", properties?.TOTAL_USER_STORIES || TOTAL_USER_STORIES)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].user_story_count !== null ? x.data[0].user_story_count : 0}</p></td>
                                             <td onClick={() => handleOpen(index, "getAllStories", "pending", properties?.PENDING || PENDING)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].pending !== null ? x.data[0].pending : 0}</p></td>
                                             <td onClick={() => handleOpen(index, "getAllStories", "completed", properties?.COMPLETED || COMPLETED)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].completed !== null ? x.data[0].completed : 0}</p></td>
                                         </tr>
                                     )
                                 }) : (
                                     searchFilterData.map((x, index) => {
                                         return (
                                             <tr key={index}>
                                                 <td style={{ height: 40, fontWeight: 'bold', color: 'green', cursor: 'pointer' }}><Link style={{ color: 'green' }} to={{ pathname: '/admin/indReports', state: { emp_id: x.emp_id, name: x.name, filter: filter, projects_involved: x.data[0].projects_involved } }} >{x.name} </Link></td>
                                                 <td style={{ height: 40 }} onClick={() => handleOpen(index, "getUserProjects", "", properties?.NO_OF_PROJECTS_INVOLVED || NO_OF_PROJECTS_INVOLVED)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].projects_involved}</p></td>
                                                 {extension === 'agile24x7.com' && <td style={{ height: 40 }} onClick={() => handleOpen(index, "getUserAccounts", "", properties?.NO_OF_ACCOUNTS || NO_OF_ACCOUNTS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].no_of_accounts}</p></td>}
                                                 <td onClick={() => handleOpen(index, "getUserTimeSheets", "", properties?.TOTAL_HOURS || TOTAL_HOURS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].working_hours !== null ? getDecimalHours(x.data[0].working_hours) : 0}</p></td>
                                                 {x.data[0].utilization_based_on_hours !== null ? <td style={{ height: 40 }} onClick={() => handleOpen(index, "getUtilizationHours", "all", properties?.USER_EFFICIENCY_HOURS || USER_EFFICIENCY_HOURS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].utilization_based_on_hours}</p></td> : <td style={{ height: 40 }}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>0</p></td>}
                                                 {x.data[0].utilization_based_on_task !== null ? <td style={{ height: 40 }} onClick={() => handleOpen(index, "getUtilizationStories", "all", properties?.USER_EFFICIENCY_STORY_POINTS || USER_EFFICIENCY_STORY_POINTS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].utilization_based_on_task}</p></td> : <td style={{ height: 40 }}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>0</p></td>}
                                                 <td onClick={() => handleOpen(index, "getOnlyStories", "all", properties?.ASSIGNED_STORY_POINTS || ASSIGNED_STORY_POINTS)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].estimated !== null ? parseInt(x.data[0].estimated) : 0}</p></td>
                                                 <td onClick={() => handleOpen(index, "getAllStories", "all", properties?.TOTAL_USER_STORIES || TOTAL_USER_STORIES)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].user_story_count !== null ? x.data[0].user_story_count : 0}</p></td>
                                                 <td onClick={() => handleOpen(index, "getAllStories", "pending", properties?.PENDING || PENDING)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].pending !== null ? x.data[0].pending : 0}</p></td>
                                                 <td onClick={() => handleOpen(index, "getAllStories", "completed", properties?.COMPLETED || COMPLETED)}><p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}>{x.data[0].completed !== null ? x.data[0].completed : 0}</p></td>
                                             </tr>
                                         )
                                     })
                                 )}
                         </tbody>
                     </table>
                 </div>
                 : loader ?
                     <RootLoader /> :
                     null}
             {
                 (open.action === "getUserTimeSheets" || open.action === "getAllStories" || open.action === "getOnlyStories" || open.action === "getUtilizationStories" || open.action === "getUtilizationHours" || open.action === "getUserProjects" || open.action==="getUserAccounts") ? <UserProjectInfo open={open.status} data={info} handleClose={handleClose} handleModalClose={handleModalClose}
                 /> : null
             }
         </div>
         
     </div>
 </div>
</div>
:  <UserCosting ActiveC={handleComponent} />
                    }
                   
                </div>
               
            </div>
        </div>
    )
}