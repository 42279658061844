import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ReportsAPI from '../../Common/AgileNetwork/ReportsAPI';
import { useSelector } from 'react-redux';
import moment from 'moment';
import $ from 'jquery';
import { getSubStringId } from '../../Common/SubStringConvert';
import { useWebProperties } from '../../Common/webProperties';
import { getDecimalHours } from '../../Common/convertDecimalHoursToTime';
import { getOnlyTimeZone } from '../../Common/showOnlyTimeZone';
import convertPSTtoLocalTime from '../../Common/convertPSTtoLocalTime';
import { isMobile } from "react-device-detect";
import MainTaskChatBox from '../../Common/ChatMainTask';
import ChatWorkflow from '../../Common/ChatWorkflow';
import RootLoader from '../../Common/Loader/RootLoader';
import Alert from '../../Common/Alert';
import API from '../../Common/Network/API';
import ChatBox from '../../Common/SquadChat';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        height: '80vh',
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 4,
    },
}));

export default function UserProjectInfo(props) {
    const getUser = useSelector(state => state.auth)
    const [result, setResult] = useState([])
    const [message, setMessage] = useState("")
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()
    const [loader, setLoader] = useState(false)
    const [editIndex, setEditIndex] = useState(null);
    const [editData, setEditData] = useState({});
    const [allUserMessages, setAllUserMessages] = useState([])
    const [allTaskMessages, setAllTaskMessages] = useState([])
    const classNames = useStyles();
    const { ASSIGNED_STORY_POINTS, MAINTASKNAME, MAINTASKS, PROJECTS_INVOLVED, STORY_POINTS, CHAT, MAINTASK, SCRUM_MASTER, PRODUCT_OWNER, role_array,AGILE_PROJECT_NAME } = useWebProperties();
    const title = props.data.action === PROJECTS_INVOLVED ? PROJECTS_INVOLVED.concat(" - ", props.data.email) :
        props.data.action === 'getTotalTicketsReport' || props.data.action === 'responseTime' || props.data.action === 'totalResolved' || props.data.action === 'totalPending' ? props.data.column_name : props.data.project_name || props.data.name;
    const properties = useSelector(state => state.landingReducer.properties);
    const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);
    async function Network(api, data, state) {
        setResult([])
        setLoader(true)
        try {
            const response = await ReportsAPI.post(`/${api}`, data, {}, false)
            state(response)
            setLoader(false)
        }
        catch (error) {
            setLoader(false)
            setMessage(error.message)
        }
    }

    async function NetworkSupportProjectReport(api, data, state) {
        try {
            const response = await ReportsAPI.post(`/${api}`, data, {}, false)
            setResult([])
            state(response)
        }
        catch (error) {
            setMessage(error.message)
        }
    }
    useEffect(() => {
        getAllUserMessages(getUser.user)
        getAllTaskMessages(getUser.user)
        if (props.data.action ==='costingProject'){
            Network('getcostingProjects', { user_id: props.data.user_id,filter: props.data.dateFilter }, setResult)

        }
        if (props.data.action ==='totalTasks'){
            Network('getcostingTasks', { emp_id: props.data.user_id,filter: props.data.dateFilter }, setResult)

        }

        if (props.data.action === 'userReports') {
            Network('projectUserReport', { project_id: props.data.project_id }, setResult)
        }
        if (props.data.action === 'getProjectActiveStoryUsers') {
            Network('getProjectActiveStoryUsers', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === 'getProjectWorkingHours') {
            Network('getProjectWorkingHours', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === 'getIndividualUserstory') {
            Network('getIndividualUserstory', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUserTimeSheets") {
            Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getAllStories") {
            Network('getAllStories', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getOnlyStories") {
            Network('getAllStories', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUtilizationStories") {
            Network('getAllStories', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUtilizationHours") {
            Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "getUserProjects") {
            Network('getUserProjectsById', { user_id: props.data.user_id, filter: props.data.dateFilter, account_id: props.data.account_id }, setResult)
        }
        if (props.data.action === "projectName") {
            Network('projectName', { email: props.data.email }, setResult)
        }
        if (props.data.action === "getUserAccounts") {
            Network('getUserAccounts', { user_id: props.data.user_id }, setResult)
        }
        if (props.data.action === "getTotalTicketsReport") {
            NetworkSupportProjectReport('getTotalTicketsReport', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "responseTime") {
            NetworkSupportProjectReport('getResponseTimeReport', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }
        if (props.data.action === "totalResolved" || props.data.action === "totalPending") {
            NetworkSupportProjectReport('getPendingCompletedReport', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
        }

        // eslint-disable-next-line
    }, [])

    const getAllUserMessages = async (getUser) => {
        try {
            var response = await API.post("squad_chat.php", {
                corp_code: getUser.corp,
                action: "get_all_messages",
                sendBy: getUser.empId,
            }, {}, false);
            if (response.status === 'True') {
                (setAllUserMessages(response.data));
            } else {

            }
        } catch (error) {
            Alert('error', error.message)
        }
    }

    const getAllTaskMessages = async (getUser) => {
        try {
            var response = await API.post(
                "user_story_chat.php",
                {
                    corp_code: getUser.corp,
                    action: "getAllTaskMessages",
                },
                {},
                false
            );
            if (response.status === "True") {
                setAllTaskMessages(response.data);
            } else {
            }
        } catch (error) {
            Alert("error", error.message);
        }
    }

    useEffect(() => {
        if (result.length > 0) {
            $(document).ready(function () {
                window.$('#user-info-table').DataTable({
                    destroy: true,
                    dom: 'Bfrtip',
                    aLengthMenu: [
                        [25, 50, 100, 200, -1],
                        [25, 50, 100, 200, "All"]
                    ],
                    iDisplayLength: -1,
                    buttons: [
                        { extend: 'excelHtml5', text: 'Export' }
                    ]
                })
            })
        }
    }, [result])

    const modCol5Array = ['getAllStories', 'getIndividualUserstory', 'getUserTimeSheets', 'getUtilizationStories', 'getUtilizationHours', 'getUserProjects', 'getOnlyStories']

    const modalBoox = (modCol5Array.includes(props.data.action)) ? "modal-content  p-2" : "modal-content  p-2"

    const getMessagesCount = (id, msg, empId, data) => {
        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={() => handleOpen("maintask", data, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold", marginTop: '-5px' }}>{msgCount.length > 9 ? "+9" : msgCount.length}</span>
                        </div>
                        :
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={() => handleOpen("maintask", data, msgCount)} />
                        </div>
                }
            </i>
        )
    }
    const getWorkflowMessagesCount = (msg, data, empId) => {
        let parts = data.split("-");
        let id = parts[0];
        let subtask_id = parts[1]

        const msgCount = msg.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === data).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })

        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={() => handlChatOpen("workflow_task", id, subtask_id, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold", marginTop: '-5px' }}>{msgCount.length > 9 ? "+9" : msgCount.length}</span>
                        </div>
                        :
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={() => handlChatOpen("workflow_task", id, subtask_id, msgCount)} />
                        </div>
                }
            </i>
        )
    }

    const handleOpen = (action, data, sno) => {
      
        var info
        setOpen({ status: true, action: action });
        if (action === 'maintask') {
            info = {
                us_id: data.id, action: action, id: data.id, sno: sno, main_task_id: data.main_task_id,
                story_type: data.story_type, project_id: data.project_id, project_name: data.project_name
            }
        }
        if (action === 'totalTasks') {
            info = {
                us_id: data.id, action: action, id: data.id, sno: sno, main_task_id: data.main_task_id,
                story_type: data.story_type, project_id: data.project_id, project_name: data.project_name
            }
        }
        setCardInfo(info);

    };

    const handlChatOpen = (action, us_id, subtask_id, sno) => {
        var info
        setOpen({ status: true, action: action });
        if (action === 'workflow_task') {
            info = { id: us_id, subtask_id: subtask_id, action: action, sno: sno }
        }
        setCardInfo(info)
    }

    const handleClose = () => {
        setOpen({ status: false, index: 0 })
        getAllUserMessages(getUser.user)
        getAllTaskMessages(getUser.user)
    };

    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
        getAllUserMessages(getUser.user)
        getAllTaskMessages(getUser.user);
    }

    const handleTimeFlag = async (flag, story_id, task_type, main_task_id, story_type, us_id, project_name, project_id, assigned_to, assigned_by, device_id, player_id, type) => {

        if (getUser.user.role === 'Admin') {
            setLoader(true)
            const response = await ReportsAPI.post(`/createTimeSheetFlag`,
                {
                    flag: flag,
                    story_id: story_id,
                    task_type: task_type,
                },
                {}, false)
            if (response.message === 'flag created') {
                if (type === 'getIndividualUserstory') {
                    Network('getIndividualUserstory', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
                }
                if (type === 'getWorkingHours') {
                    let message
                    if (flag === '1') {
                        message = `Flag Created: The target time for ${MAINTASK} ${getSubStringId(project_name, 2)}-${us_id} has been exceeded. Please provide an explanation for the delay.`;
                    } else {
                        message = `Flag Closed: The issue regarding ${MAINTASK} ${getSubStringId(project_name, 2)}-${us_id} has been resolved. The flag is now closed.`;
                    }
                    sendMessageToEmp(message, project_id, device_id, player_id, assigned_to)
                    Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
                }
            }
        } else {
            if (getUser.user.empId !== assigned_to) {
                if (getUser.user.empId === assigned_by || accessRole) {
                    setLoader(true)
                    const response = await ReportsAPI.post(`/createTimeSheetFlag`,
                        {
                            flag: flag,
                            story_id: story_id,
                            task_type: task_type,
                        },
                        {}, false)
                    if (response.message === 'flag created') {
                        if (type === 'getIndividualUserstory') {
                            let message
                            if (flag === '1') {
                                message = `Flag Created: The target time for ${MAINTASK} ${getSubStringId(project_name, 2)}-${us_id} has been exceeded. Please provide an explanation for the delay.`;
                            } else {
                                message = `Flag Closed: The issue regarding ${MAINTASK} ${getSubStringId(project_name, 2)}-${us_id} has been resolved. The flag is now closed.`;
                            } sendMessageToEmp(message, project_id, device_id, player_id, assigned_to)
                            Network('getIndividualUserstory', { project_id: props.data.project_id, filter: props.data.dateFilter }, setResult)
                        }
                        if (type === 'getWorkingHours') {
                            Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)
                        }
                    }
                } else {
                    Alert('warning', 'You cannot have access to flag timesheet records.')

                }
            } else {
                Alert('warning', 'You cannot have access to flag timesheet records.')
            }
        }
    }

    const handleEditClick = (index, rowData) => {
        setEditIndex(index);
        setEditData(rowData);
    };

    const handleSaveClick = async () => {

        let start_time = moment(editData.start_time).format('YYYY-MM-DD HH:mm:ss')
        let end_time = moment(editData.end_time).format('YYYY-MM-DD HH:mm:ss')

        let startMoment = moment(start_time, 'YYYY-MM-DD HH:mm:ss');
        let endMoment = moment(end_time, 'YYYY-MM-DD HH:mm:ss');

        if (endMoment.isBefore(startMoment)) {
            Alert('warning', `${MAINTASK} End time cannot be before ${MAINTASK} start time`);
        } else {
            setLoader(true)
            setEditIndex(null);
            setEditData({});
            const response = await ReportsAPI.post(`/editTimeSheetRecord`,
                {
                    t_id: editData.t_id,
                    start_time: startMoment,
                    end_time: endMoment,
                },
                {}, false)
            if (response.message === 'Time sheet record updated') {
                Network('getWorkingHours', { user_id: props.data.user_id, filter: props.data.dateFilter }, setResult)

            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditData(prevState => ({ ...prevState, [name]: value }));
    };


    const sendMessageToEmp = async (message, project_id, device_id, player_id, assigned_to) => {
        try {
            // eslint-disable-next-line
            await API.post("squad_chat.php", {
                action: "send",
                corp_code: getUser.user.corp,
                sendBy: getUser.user.empId,
                receivedBy: assigned_to,
                message: message,
                messagedBy: getUser.user.empId,
                device_id: device_id, //for notifications
                player_id: player_id, //for notifications
                projectId: project_id,
                reply_id: "",
            }, {}, false);
        } catch (error) {
            console.log(error)
        }
    }


    const getUserMessagesCount = (msg, emp) => {
        const msgCount = msg?.filter(message => message.sendBy === emp?.user_id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })

        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-10px" }}
                                onClick={() => handleOpenChat(emp, "user_chat", msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold", marginLeft: "-2px", cursor: 'pointer' }}>{msgCount.length > 9 ? "9+" : msgCount.length}</span>
                        </div>
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-10px", cursor: 'pointer' }}
                                onClick={() => handleOpenChat(emp, "user_chat", msgCount)} />
                        </div>
                }
            </i>
        )
    }

    const handleOpenChat = (emp, action, sno) => {
        setOpen({ status: true, action: action });
        var info = {
            id: emp.user_id,
            name: emp.name,
            action: action,
            email: emp.email,
            device_id: emp.device_id,
            player_id: emp.player_id,
            sno: sno,
            u_id: emp.u_id
        }

        setCardInfo(info);
    };
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center" style={{ height: '80vh' }}>
                            <div className={modalBoox} style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <div className="column">
                                        {props.data.action === 'getTotalTicketsReport' || props.data.action === 'responseTime' || props.data.action === 'totalResolved' || props.data.action === 'totalPending'
                                            ?
                                            (<h6 class="modal-title p-1">{title}</h6>)
                                            :
                                            (
                                                props.data.action === "getUserTimeSheets" ?
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div>
                                                            <h6 class="modal-title p-1">{title} - {props.data.column_name}</h6>
                                                        </div>
                                                        {
                                                            props.data.from === 'admin' ?
                                                                <div style={{ marginLeft: '35px' }}>
                                                                    {
                                                                        getUserMessagesCount(allUserMessages, props.data)
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    :
                                                    <h6 class="modal-title p-1">{title} - {props.data.column_name}</h6>
                                            )
                                        }
                                    </div>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={props.handleModalClose} className="d-flex align-items-right" data-dismiss="modal"><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body" style={{ overflow: 'auto' }}>
                                    <div className="overflow-auto" style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                                        {props.data.action === 'Projects Involved' && (result.length > 0 ?
                                            result.map(({ project_name }, index) => {
                                                return (
                                                    <p className="text-left" > <h6 className="pt-1" style={{ color: 'grey', textTransform: "capitalize" }}>{index + 1}. {project_name}</h6></p>
                                                )
                                            }) : <p>{message}</p>)}
                                        {props.data.action === 'userReports' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>User</th>
                                                        <th>Active Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ working_status, fullname }, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{fullname}</td>
                                                                    <td style={{ color: working_status === 'Active' ? 'green' : 'red' }}>{working_status}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getUserProjects' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>Project</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ project_name }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}
                                        {/* costing projects */}
                                        {props.data.action === 'costingProject' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>Project Name</th>
                                                        <th>Tasks</th>
                                                        <th>Working Hours </th>
                                                        <th>Bill Rate</th>
                                                        <th>Pay Rate</th>
                                                        <th>Net Income </th>


                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ Project_Name,Tasks,Bill_Rate,Working_Hours}, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{Project_Name}</td>
                                                                    <td>{Tasks}</td>
                                                                    <td>{Working_Hours !== null ? getDecimalHours(Working_Hours) : '0'}</td>                     
                                                                    <td>{Bill_Rate}/hr</td>
                                                                    <td>{props.data.pay_rate}/hr</td>
                                                                    
                                                                    {/* <td>{ (x.data[0].Total_No_of_Hours_Worked !== null && x.pay_rate !==0) ? (x.data[0].Total_No_of_Hours_Worked * x.pay_rate):"0"}</td> */}

                                                                    {/* <td>{(Working_Hours!== null && props.data.pay_rate!==0)? (`${(Working_Hours * props.data.pay_rate).toFixed(2)}/hr`) : '0'}</td> */}
                                                                    <td>
  {(Working_Hours !== null && props.data.pay_rate !== 0) 
    ? (Bill_Rate === '0' 
        ? `-${(Working_Hours * props.data.pay_rate).toFixed(2)}/hr` 
        : `${(Working_Hours * props.data.pay_rate).toFixed(2)}/hr`) 
    : '0'}
</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}

                                        
                                        {/* get costing total tasks */}
                                        {props.data.action === 'totalTasks' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>Project Name</th>
                                                        <th>Tasks</th>
                                                        <th>Estimated Hours</th>
                                                        <th>Start Time</th>
                                                        <th>End Time </th>
                                                        <th>Working Hours </th>
                                                        <th>Chat</th>
                                                    </tr>
                                                </thead>
                                                 <tbody>
                                                    {
                                                        result.map(({ project_name,us_id,story_title,story_points,start_time,end_time,working_hours ,  task_type,story_id,main_task_id,story_type,project_id}, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{us_id}-{story_title}</td>
                                                                    <td>{story_points}</td>
                                                                    {/* <td>{start_time === null ? moment(start_time).format('MM/DD/YYYY') : <>{moment(start_time).format('MM/DD/YYYY hh:mm:ss A')}{" "}{getOnlyTimeZone()}</>}</td> */}
                                                                    {/* <td>{end_time === null ? moment(end_time).format('MM/DD/YYYY') : <>{moment(end_time).format('MM/DD/YYYY hh:mm:ss A')}{" "}{getOnlyTimeZone()}</>}</td> */}

                                                                    {/* <td>{start_time !== null ? getDecimalHours(start_time) : '0'}</td>                      */}
                                                                    <td>{start_time !== null ? <>{moment(start_time).format('MM/DD/YYYY hh:mm:ss A')}{" "}{getOnlyTimeZone()}</> : '0'}</td>                     

                                                                    <td>{end_time !== null ? <>{moment(end_time).format('MM/DD/YYYY hh:mm:ss A')}{" "}{getOnlyTimeZone()}</> : '0'}</td>                     

                                                                    <td>{working_hours !== null ? getDecimalHours(working_hours) : '0'}</td>                     
                                                                    {/* <td>{(Working_Hours!== null && props.data.pay_rate!==0)? (`${Working_Hours * props.data.pay_rate}/hr`) : '0'}</td> */}
                                                                   
                                                                     <td>
                                                                                     {
                                                                                         task_type === 'main_task' ?

                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }}>
                                                                                                {
                                                                                                   getMessagesCount(us_id, allTaskMessages, getUser.user.empId, {
                                                                                                    id: us_id,
                                                                                                    userstory_id: story_id,
                                                                                                    main_task_id: main_task_id,
                                                                                                    story_type: story_type,
                                                                                                    project_name: project_name,
                                                                                                    project_id: project_id
                                                                                                })
                                                                                                }
                                                                                            </button>
                                                                                            :
                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }} >
                                                                                                {
                                                                                                    getWorkflowMessagesCount(allTaskMessages, us_id, getUser.user.empId)
                                                                                                }
                                                                                            </button>

                                                                                    }
                                                                                </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody> 
                                            </table>
                                            : <p>{message}</p>)}
                                       {/*  */}
                                        {props.data.action === 'getUserAccounts' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>Account</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ client_name }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{client_name ? client_name : "Test Account"}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}

                                        {props.data.action === 'getProjectWorkingHours' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNo</th>
                                                        <th>User</th>
                                                        <th>Working Hours</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ hours, fullname }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{fullname}</td>
                                                                    <td>{getDecimalHours(hours)}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table> : <p>{message}</p>)}
                                        {props.data.action === 'getIndividualUserstory' && (
                                            loader ?
                                                <RootLoader />
                                                :
                                                result.length > 0 ?
                                                    <div>
                                                        <table className='table table-bordered mb-1 rwd-table' id="user-info-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>SNo</th>
                                                                    <th>User</th>
                                                                    <th>{MAINTASKNAME}</th>
                                                                    <th>Estimated Hours</th>
                                                                    <th>Working Hours</th>
                                                                    <th>Chat</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    result.map(({ story_title, fullname, working_hours, us_id, story_points, story_id, story_type, task_type, main_task_id, project_name, time_sheet_flag, project_id, assigned_to, assigned_by, device_id, player_id }, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{fullname}</td>
                                                                                <td>{getSubStringId(project_name, 2)}-{us_id}-{story_title}</td>
                                                                                <td>{story_points}</td>
                                                                                <td>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <div>
                                                                                            {getDecimalHours(working_hours)}
                                                                                        </div>
                                                                                        {/* <div><img alt="" style={{ width: '15px', height: '15px', margin: '5px' }} src={"/images/common/flag-edit.png"} /></div> */}
                                                                                        {
                                                                                            time_sheet_flag === '1' ?
                                                                                                <div><img alt="" style={{ width: '20px', height: '20px', marginLeft: '20px', cursor: 'pointer' }} src={"/images/common/red-flag.png"} onClick={() => handleTimeFlag("0", story_id, task_type, main_task_id, story_type, us_id, project_name, project_id, assigned_to, assigned_by, device_id, player_id, 'getIndividualUserstory')} /></div>
                                                                                                :
                                                                                                <div><img alt="" style={{ width: '20px', height: '20px', marginLeft: '20px', cursor: 'pointer' }} src={"/images/common/black-flag.png"} onClick={() => handleTimeFlag("1", story_id, task_type, main_task_id, story_type, us_id, project_name, project_id, assigned_to, assigned_by, device_id, player_id, 'getIndividualUserstory')} /></div>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        task_type === 'main_task' ?

                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }} >
                                                                                                {
                                                                                                    getMessagesCount(us_id, allTaskMessages, getUser.user.empId, {
                                                                                                        id: us_id,
                                                                                                        userstory_id: story_id,
                                                                                                        main_task_id: main_task_id,
                                                                                                        story_type: story_type,
                                                                                                        project_name: project_name,
                                                                                                        project_id: project_id
                                                                                                    })
                                                                                                }
                                                                                            </button>
                                                                                            :
                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }} >
                                                                                                {
                                                                                                    getWorkflowMessagesCount(allTaskMessages, us_id, getUser.user.empId)
                                                                                                }
                                                                                            </button>

                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : <p>{message}</p>)}
                                        {props.data.action === 'getUserTimeSheets' && (loader ?
                                            <RootLoader />
                                            :
                                            result.length > 0 ?
                                                <div>
                                                    <table className='table table-bordered mb-1' id="user-info-table">
                                                        <thead>
                                                            <tr>
                                                                <th>SNO</th>
                                                                {/* <th>{PROJECT}</th> */}
                                                                <th>{AGILE_PROJECT_NAME}</th>
                                                                <th>{MAINTASKS || properties.USER_STORIES}</th>
                                                                <th>Date</th>
                                                                <th>Start time</th>
                                                                <th>End time</th>
                                                                <th>Estimated Hours</th>
                                                                <th>Working Hours</th>
                                                                <th>Actions</th>
                                                                <th>Chat</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                result.map((rowData, index) => {
                                                                    const {
                                                                        story_title, total_working_hours, updated_date, us_id, start_time, end_time, story_points,
                                                                        time_sheet_flag, project_name, project_id, main_task_id, task_type, story_type, story_id, assigned_to, device_id, player_id, assigned_by
                                                                    } = rowData;

                                                                    if (editIndex === index) {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{editData.project_name}</td>
                                                                                <td>{`${getSubStringId(editData.project_name, 3)}-${editData.us_id}-${editData.story_title}`}</td>
                                                                                <td>{moment(editData.updated_date).format('MM/DD/YYYY')}</td>
                                                                                <td><input type="datetime-local" name="start_time" value={start_time ? moment(editData.start_time).format('YYYY-MM-DDTHH:mm:ss') : ''} onChange={handleChange} />{" "}{getOnlyTimeZone()}</td>
                                                                                <td><input type="datetime-local" name="end_time" value={end_time ? moment(editData.end_time).format('YYYY-MM-DDTHH:mm:ss') : ''} onChange={handleChange} />{" "}{getOnlyTimeZone()}</td>
                                                                                <td>{editData.story_points}</td>
                                                                                <td>{getDecimalHours(editData.total_working_hours)}</td>
                                                                                <td>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        <img alt="" style={{ width: '20px', height: '20px', marginLeft: '10px', cursor: 'pointer' }} src={"/images/common/save.png"} onClick={() => handleSaveClick()} />
                                                                                        <img alt="" style={{ width: '20px', height: '20px', marginLeft: '20px', cursor: 'pointer' }} src={"/images/common/cancel.png"} onClick={() => setEditIndex(null)} />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{project_name}</td>
                                                                                <td>{`${getSubStringId(project_name, 3)}-${us_id}-${story_title}`}</td>
                                                                                <td>{moment(updated_date).format('MM/DD/YYYY')}</td>
                                                                                <td>{start_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{moment(start_time).format('MM/DD/YYYY hh:mm:ss A')}{" "}{getOnlyTimeZone()}</>}</td>
                                                                                <td>{end_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{(moment(end_time).format('MM/DD/YYYY hh:mm:ss A'))}{" "}{getOnlyTimeZone()}</>}</td>
                                                                                <td>{story_points}</td>
                                                                                <td>
                                                                                    <div>{getDecimalHours(total_working_hours)}</div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                        {time_sheet_flag === '1' ? (
                                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                {
                                                                                                    props.data.from === 'admin' ?
                                                                                                        <img alt="" style={{ width: '20px', height: '20px', marginLeft: '10px', cursor: 'pointer' }} src={"/images/common/flag-edit.png"} onClick={() => handleEditClick(index, rowData)} />
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                <img alt="" style={{ width: '20px', height: '20px', marginLeft: props.data.from !== 'admin' ? '50px' : '20px', cursor: 'pointer' }} src={"/images/common/red-flag.png"} onClick={() => handleTimeFlag("0", story_id, task_type, main_task_id, story_type, us_id, project_name, project_id, assigned_to, assigned_by, device_id, player_id, 'getWorkingHours')} />
                                                                                            </div>
                                                                                        ) : (

                                                                                            <img alt="" style={{ width: '20px', height: '20px', marginLeft: '50px', cursor: 'pointer' }} src={"/images/common/black-flag.png"} onClick={() => handleTimeFlag("1", story_id, task_type, main_task_id, story_type, us_id, project_name, project_id, assigned_to, assigned_by, device_id, player_id, 'getWorkingHours')} />
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        task_type === 'main_task' ?

                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }} >
                                                                                                {
                                                                                                    getMessagesCount(us_id, allTaskMessages, getUser.user.empId, {
                                                                                                        id: us_id,
                                                                                                        userstory_id: story_id,
                                                                                                        main_task_id: main_task_id,
                                                                                                        story_type: story_type,
                                                                                                        project_name: project_name,
                                                                                                        project_id: project_id
                                                                                                    })
                                                                                                }
                                                                                            </button>
                                                                                            :
                                                                                            <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '20px', padding: "0", marginRight: 10, marginBottom: 'auto', marginTop: '5px', display: isMobile ? "none" : {} }} >
                                                                                                {
                                                                                                    getWorkflowMessagesCount(allTaskMessages, us_id, getUser.user.empId)
                                                                                                }
                                                                                            </button>

                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                })
                                                            }
                                                        </tbody>
                                                    </table >
                                                </div>

                                                : <p>{message}</p>)}
                                        {props.data.action === 'getProjectActiveStoryUsers' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        <th>Users</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map(({ fullname }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{fullname}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getOnlyStories' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        {/* <th>{PROJECT}</th> */}
                                                        <th>{AGILE_PROJECT_NAME}</th>
                                                        <th>{MAINTASKS}</th>
                                                        <th>{ASSIGNED_STORY_POINTS}</th>
                                                        <th>Complete Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, us_id, story_points }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    <td>{story_points}</td>
                                                                    <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getAllStories' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        {/* <th>{PROJECT}</th> */}
                                                        <th>{AGILE_PROJECT_NAME}</th>
                                                        <th>{MAINTASKS}</th>
                                                        <th>Complete Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, us_id }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getUtilizationStories' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        {/* <th>{PROJECT}</th> */}
                                                        <th>{AGILE_PROJECT_NAME}</th>
                                                        <th>{MAINTASKS}</th>
                                                        {/* <th>{KANBAN}</th> */}
                                                        <th>{STORY_POINTS}</th>
                                                        <th>{MAINTASKS} Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, story_points, kanban_status, us_id }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    {/* <td style={{ color: kanban_status === '0' ? "red" : "green" }}>{kanban_status === "0" ? "NO" : "YES"}</td> */}
                                                                    <td>{story_points}</td>
                                                                    <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getUtilizationHours' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>SNO</th>
                                                        {/* <th>{PROJECT}</th> */}
                                                        <th>{AGILE_PROJECT_NAME}</th>
                                                        <th>{MAINTASKS}</th>
                                                        {/* <th>{KANBAN}</th> */}
                                                        <th>Date</th>
                                                        <th>Start Time</th>
                                                        <th>End Time</th>
                                                        <th>Estimated Hours</th>
                                                        <th>Working Hours</th>
                                                        {/* <th>{MAINTASKS} Status</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.filter((info) => props.data.task_status === "all" ? props.data.task_status !== info.complete_status : props.data.task_status === info.complete_status).map(({ story_title, project_name, complete_status, working_hours, updated_date, kanban_status, us_id, total_working_hours, start_time, end_time, story_points }, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{project_name}</td>
                                                                    <td>{getSubStringId(project_name, 3)}{'-'}{us_id}{'-'}{story_title}</td>
                                                                    {/* <td style={{ color: kanban_status === '0' ? "red" : "green" }}>{kanban_status === "0" ? "NO" : "YES"}</td> */}
                                                                    <td>{moment(updated_date).format('MM/DD/YYYY')}</td>
                                                                    <td>{start_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{moment(start_time).format('MM/DD/YYYY hh:mm:ss A')}{" "}{getOnlyTimeZone()}</>}</td>
                                                                    <td>{end_time === null ? moment(updated_date).format('MM/DD/YYYY') : <>{(moment(end_time).format('MM/DD/YYYY hh:mm:ss A'))}{" "}{getOnlyTimeZone()}</>}</td>
                                                                    <td>{story_points}</td>
                                                                    <td>{getDecimalHours(total_working_hours)}</td>
                                                                    {/* <td style={{ color: complete_status === 'pending' ? "red" : "green" }}>{`${complete_status}`.toUpperCase()}</td> */}
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table >
                                            : <p>{message}</p>)}
                                        {props.data.action === 'getTotalTicketsReport' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Created Date & Time</th>
                                                        <th>Platform</th>
                                                        <th>Main Ticket into RB</th>
                                                        <th>Generated Ticket in TODO</th>
                                                        <th>Support Ticket Details</th>
                                                        <th>Assigned by</th>
                                                        <th>Assigned to</th>
                                                        <th>Reassigned to Developer</th>
                                                        <th>Generated Ticket Status</th>
                                                        <th>Main Ticket Status</th>
                                                        <th>Priority/Severity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map((info, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{convertPSTtoLocalTime(info.date)} </td>
                                                                    <td>{info.device_type_blocked || info.device_type_main || 'NA'}</td>
                                                                    <td>{info.mtrb}</td>
                                                                    <td>{info.gtodo ? info.gtodo : 'NA'}</td>
                                                                    <td>{info.support_tkt_details ? info.support_tkt_details : info.name}</td>
                                                                    <td>{info.assigned_by_name}</td>
                                                                    <td>{info.assigned_to_name}</td>
                                                                    <td>{info.reassign_developer ? info.reassign_developer : 'NA'}</td>
                                                                    <td>{info.gen_tkt_status ? info.gen_tkt_status : 'NA'}</td>
                                                                    <td>{info.main_tkt_status}</td>
                                                                    <td>{info.priority_level ? info.priority_level : 'NA'}</td>
                                                                </tr>

                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>
                                        )}
                                        {props.data.action === 'responseTime' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Task Id</th>
                                                        <th>Ticket Generated Date & Time</th>
                                                        <th>Name</th>
                                                        <th>Assigned by</th>
                                                        <th>Assigned to</th>
                                                        <th>Responded By</th>
                                                        <th>Initial Response Time(Support Team)</th>
                                                        <th>Response Time(User)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.map((info, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{info.task_id} </td>
                                                                    <td>{info.created_date ? convertPSTtoLocalTime(info.created_date) : "NA"}</td>
                                                                    <td>{info.name ? info.name : "NA"}</td>
                                                                    <td>{info.assigned_by_name}</td>
                                                                    <td>{info.assigned_to_name}</td>
                                                                    <td>{info.messaged_by_name ? info.messaged_by_name : "NA"}</td>
                                                                    <td>{info.support_resp_time ? `${info.support_resp_time} secs` : 'Not Responding'}</td>
                                                                    <td>{info.user_resp_time ? `${info.user_resp_time} secs` : 'Not Responding'}</td>
                                                                </tr>

                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>
                                        )}
                                        {props.data.action === 'totalResolved' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Task Id</th>
                                                        <th>Created Date & Time</th>
                                                        <th>Name</th>
                                                        <th>Assigned by</th>
                                                        <th>Assigned to</th>
                                                        <th>Completed Date & Time</th>
                                                        <th>Ticket Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.flatMap((info, index) => {
                                                            const elements = [];
                                                            if (info.main_tkt_status === 'completed') {
                                                                elements.push(
                                                                    <tr key={index}>
                                                                        <td>{info.mtrb} </td>
                                                                        <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                        <td>{info.name}</td>
                                                                        <td>{info.assigned_by_name}</td>
                                                                        <td>{info.assigned_to_name}</td>
                                                                        <td>{convertPSTtoLocalTime(info.main_tkt_end_date)}</td>
                                                                        <td>Main Ticket</td>
                                                                    </tr>
                                                                );
                                                            }

                                                            if (info.gtodo && info.gen_tkt_status === 'completed') {
                                                                elements.push(
                                                                    <tr key={`${index}-gtodo`}>
                                                                        <td>{info.gtodo} </td>
                                                                        <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                        <td>{info.name}</td>
                                                                        <td>{info.assigned_to_name}</td>
                                                                        <td>{info.reassign_developer}</td>
                                                                        <td>{convertPSTtoLocalTime(info.blocked_tkt_end_date)}</td>
                                                                        <td>Blocked Ticket</td>
                                                                    </tr>
                                                                );
                                                            }
                                                            return elements;
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>
                                        )}
                                        {props.data.action === 'totalPending' && (result.length > 0 ?
                                            <table className='table table-bordered mb-1' id="user-info-table">
                                                <thead>
                                                    <tr>
                                                        <th>Task Id</th>
                                                        <th>Ticket Generated Date & Time</th>
                                                        <th>Name</th>
                                                        <th>Assigned by</th>
                                                        <th>Assigned to</th>
                                                        <th>Ticket Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        result.flatMap((info, index) => {
                                                            const elements = [];
                                                            if (info.main_tkt_status === 'pending') {
                                                                elements.push(
                                                                    <tr key={index}>
                                                                        <td>{info.mtrb} </td>
                                                                        <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                        <td>{info.name}</td>
                                                                        <td>{info.assigned_by_name}</td>
                                                                        <td>{info.assigned_to_name}</td>
                                                                        <td>Main Ticket</td>
                                                                    </tr>
                                                                );
                                                            }

                                                            if (info.gtodo && info.gen_tkt_status === 'pending') {
                                                                elements.push(
                                                                    <tr key={`${index}-gtodo`}>
                                                                        <td>{info.gtodo} </td>
                                                                        <td>{convertPSTtoLocalTime(info.date)}</td>
                                                                        <td>{info.name}</td>
                                                                        <td>{info.assigned_to_name}</td>
                                                                        <td>{info.reassign_developer}</td>
                                                                        <td>Blocked Ticket</td>
                                                                    </tr>
                                                                );
                                                            }
                                                            return elements;
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <p>{message}</p>
                                        )}
                                    </div>
                                </div>
                                {
                                   ( open.action === "maintask" || open.action === "totalTasks") ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                    /> : null
                                }
                                {
                                    (open.action === "workflow_task"|| open.action === "totalTasks") ? <ChatWorkflow open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                                    /> : null
                                }
                                {open.action === "user_chat" ? (
                                    <ChatBox
                                        open={open.status}
                                        handleClose={handleClose}
                                        data={cardInfo}
                                        handleModalClose={handleModalClose}
                                    />
                                ) : null}

                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-success" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}