// File: addToSprintORKanban.js
// Purpose: Start the task from Mytasker which is in Backlogs and not added to any Board before, So Here added that task to any one of the board depend on Target time and then start the task
// Developer: Naveen Kumar 

import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";
import { getActiveSubUserStory, getActiveUserStory } from "./getActiveUserStory";

export async function addToSprintORKanban(getUser, story_id, MAINTASK, handleClose, handleOpen, message, project_name = null, project_id, targetDate, SPRINT_OR_KANBAN_WARNING) {
  try {
    var response = await API.post(
      "getUpdateSprint.php",
      {
        crop: getUser.corp,
        userStoryId: story_id,
        type: "sprint",
        projectId: project_id,
        action: "add_to_sprint_or_kanban",
      },
      {},
      false
    );
    if (response.status === "True") {
      const moduleId = response.sprintId;
      const sprint_or_kanban_success_msg = `${MAINTASK} has been successfully started`;
      await getActiveUserStory(getUser, story_id, moduleId, handleClose, handleOpen, message, project_name, sprint_or_kanban_success_msg)
    } else {
      Alert("warning", SPRINT_OR_KANBAN_WARNING);
    }
  } catch (error) {
    console.log(error);
  }
}

export async function addToSprintORKanbanSubTask(getUser, task_id, sprint_id, handleClose, handleOpen, message, project_name, main_task_id, story_id, project_id, us_id, priority, SPRINT_OR_KANBAN_WARNING) {
  try {
    var response = await API.post(
      "getUpdateSprint.php",
      {
        crop: getUser.corp,
        userStoryId: story_id,
        type: "sprint",
        projectId: project_id,
        action: "add_to_sprint_or_kanban",
      },
      {},
      false
    );
    if (response.status === "True") {
      const moduleId = response.sprintId;
      await getActiveSubUserStory(getUser.user, task_id, moduleId, handleClose, handleOpen, message, project_name, main_task_id, story_id, project_id, us_id, priority)

    } else {
      Alert("warning", SPRINT_OR_KANBAN_WARNING);
    }
  } catch (error) {
    console.log(error);
  }
}