import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useWebProperties } from '../../../Common/webProperties';
import { logoutUser } from '../../../Common/Actions';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logEntry } from '../../../Common/logEntry';

export default function SideBar() {
  const getUser = useSelector(state => state.auth)
  const activities = useSelector(state => state.landingReducer.userActivities)
  const dispatch = useDispatch();
  const location = useLocation();
  const isModuleActive = location.pathname.startsWith('/Modules') || location.pathname.startsWith('/userProjectInfo');


  const { MODULES, KANBAN, SCRUM_MASTER, PRODUCT_OWNER, MANAGEPROJECTS,
    COMPLETED_PROJECTS, USERS_ROADBLOCKS, PENDING_SUBTASKS, EMPLOYEES, ARCHIVE, CALENDAR,
    REPORTS, KANBAN_NAME, role_array, SCRUM_BOARD, GROUP_ENABLE, SUPPORT_ENABLE, ROADBLOCK_ENABLE } = useWebProperties();
 
  const isArchiveActive = location.pathname === `/${ARCHIVE}` || location.pathname === '/mainTaskSubTaskTabs';
  const isBacklogActive = location.pathname === '/templateView' || location.pathname === `/${PENDING_SUBTASKS}` || location.pathname==='/viewSubTasks' || location.pathname==='/viewGroupTasks';
  const redirect = () => {
    return <Redirect to="/" />
  }
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item" 
                onClick={() => {
                  activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                    sub_activity:'Dashboard'
                  })
                }}
        
        >
          <NavLink
            activeStyle={{
              fontWeight: "bold",
              color: "#007bff"
            }}
            className="nav-link" to={{ pathname: '/dashboard' }}>
            <img src="images/common/homepage.png" alt="logo" style={{ width: '30px', height: '30px' }} />
            <span className="menu-title pl-3">Dashboard</span>
          </NavLink>
        </li>
        <li className="nav-item" 
                onClick={() => {
                  activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                    sub_activity:`${MODULES}`
                  })
                }}
        
        >
          <NavLink
            activeStyle={{
              fontWeight: "bold",
              color: "#007bff"
            }}
            className="nav-link" to={{ pathname: `/${MODULES}` }}>
            <img src="images/common/module.svg" alt="logo" style={{ width: '30px', height: '30px' }} />
            <span className="menu-title pl-3">{SCRUM_BOARD}</span>
          </NavLink>
        </li>
        {
          SUPPORT_ENABLE === '1' ?
            <li className="nav-item"
            onClick={() => {
              activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                sub_activity:`${KANBAN_NAME}`
              })
            }}
            
            >
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
                className="nav-link" to={{ pathname: `/${KANBAN_NAME}` }}>
                <img src="images/common/clipboard.png" alt="logo" style={{ width: '30px', height: '30px' }} />
                <span className="menu-title pl-3">{KANBAN}</span>
              </NavLink>
            </li>
            :
            null
        }
        <li className={`nav-item ${isModuleActive ? 'active' : ''}`}
        
        onClick={() => {
          activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
            sub_activity:`${MANAGEPROJECTS}`
          })
        }}
        
        >
          <NavLink
            activeStyle={{
              fontWeight: "bold",
              color: "#007bff"
            }}
            className="nav-link"
            to={{ pathname: `/user/${MANAGEPROJECTS}` }}
          >
            <img src="images/common/projects.svg" alt="logo" style={{ width: '30px', height: '30px' }} />
            <span className="menu-title pl-3" name='modules'>{MANAGEPROJECTS}</span>
          </NavLink>
        </li>

        {/* <li className="nav-item">
              <NavLink 
                 activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
              className="nav-link" to={{pathname : "/managetasks"}}>
              <img src="images/common/managetasks.svg" alt="logo" style={{width:'30px',height:'30px'}}/>
                <span className="menu-title pl-3">{MAINTASKS}</span>
              </NavLink>
            </li> */}
        <li className={isBacklogActive ? 'nav-item active' : 'nav-item'}
                onClick={() => {
                  activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                    sub_activity:`${PENDING_SUBTASKS}`
                  })
                }}
        
        
        >
          <NavLink
            activeStyle={{
              fontWeight: "bold",
              color: "#007bff"
            }}
            className="nav-link" to={{ pathname: `/${PENDING_SUBTASKS}` }}>
            <img src="images/common/sidesubtask.svg" alt="logo" style={{ width: '30px', height: '30px' }} />
            <span className="menu-title pl-3" name="tasks">{PENDING_SUBTASKS}</span>
          </NavLink>
        </li>

        {
          ROADBLOCK_ENABLE === '1' ?
            (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER) ? <li className="nav-item"
            
            onClick={() => {
              activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                sub_activity:`${USERS_ROADBLOCKS}`
              })
            }}
            
            >
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
                className="nav-link" to={{ pathname: `/${USERS_ROADBLOCKS}` }}>
                <img src="images/common/roadside.svg" alt="logo" style={{ width: '30px', height: '30px' }} />
                <span className="menu-title pl-3">{USERS_ROADBLOCKS}</span>
              </NavLink>
            </li> : null :
            null
        }
        {
          GROUP_ENABLE === '1' ?
            <li className="nav-item" 
            onClick={() => {
              activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                sub_activity:"Group"
              })
            }}
            
            >
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "#007bff"
                }}
                className="nav-link" to={{ pathname: "/chat" }}>
                <img src="images/common/chat-3.png" alt="logo" style={{ width: '30px', height: '30px' }} />
                <span className="menu-title pl-3">Group</span>
              </NavLink>
            </li>
            :
            null
        }
        <li className="nav-item" 
            onClick={() => {
              activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                sub_activity:`${CALENDAR}`
              })
            }}
        
        >
          <NavLink
            activeStyle={{
              fontWeight: "bold",
              color: "#007bff"
            }}
            className="nav-link" to={{ pathname: "/calendar" }}>
            <img src="images/common/calender.jpeg" alt="logo" style={{ width: '30px', height: '30px' }} />
            <span className="menu-title pl-3">{CALENDAR}</span>
          </NavLink>
        </li>
        {<li className="nav-item" 
        
        onClick={() => {
          activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
            sub_activity:`${EMPLOYEES}`
          })
        }}        
        >
          <NavLink
            activeStyle={{
              fontWeight: "bold",
              color: "#007bff"
            }}
            className="nav-link" to={{ pathname: "/teams" }}>
            <img src="images/common/teams.png" alt="logo" style={{ width: '30px', height: '30px' }} />
            <span className="menu-title pl-3" name='team'>{EMPLOYEES}</span>
          </NavLink>
        </li>}

        {/* <li className="nav-item">
          <NavLink
            activeStyle={{
              fontWeight: "bold",
              color: "#007bff"
            }}
            className="nav-link" to={{ pathname: `/${MURAL_BOARD}` }}>
            <img src="images/common/mural_icon.png" alt="logo" style={{ width: '30px', height: '30px' }} />
            <span className="menu-title pl-3">{MURAL_BOARD}</span>
          </NavLink>
        </li> */}
        <li className="nav-item" 
            onClick={() => {
              activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                sub_activity:`${REPORTS}`
              })
            }}

        >
          <NavLink
            activeStyle={{
              fontWeight: "bold",
              color: "#007bff"
            }}
            className="nav-link" to={{ pathname: "/reports" }}>
            <img src="images/common/reports.svg" alt="logo" style={{ width: '30px', height: '30px' }} />
            <span className="menu-title pl-3">{REPORTS}</span>
          </NavLink>
        </li>
        <li className="nav-item" 
                onClick={() => {
                  activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                    sub_activity:`${COMPLETED_PROJECTS}`
                  })
                }}
        
        >
          <NavLink
            activeStyle={{
              fontWeight: "bold",
              color: "#007bff"
            }}
            className="nav-link" to={{ pathname: `/${COMPLETED_PROJECTS}` }}>
            <img src="images/common/completed.png" alt="logo" style={{ width: '30px', height: '30px' }} />
            <span className="menu-title pl-3">{COMPLETED_PROJECTS}</span>
          </NavLink>
        </li>
        <li className={isArchiveActive ? 'nav-item active' : 'nav-item' }
                 onClick={() => {
                  activities && logEntry({user_id:getUser.user.userName,logging_level:3,activity_id:activities["Page Navigation"],
                    sub_activity:`${ARCHIVE}`
                  })
                }}

        >
          <NavLink
            activeStyle={{
              fontWeight: "bold",
              color: "#007bff"
            }}
            className="nav-link" to={{ pathname: `/${ARCHIVE}` }}>
            <img src="images/common/server.png" alt="logo" style={{ width: '30px', height: '30px' }} />
            <span className="menu-title pl-3">{ARCHIVE}</span>
          </NavLink>
        </li>
        <li className="nav-item arc">
          <label
            onClick={() => {
              dispatch(logoutUser)
              activities && logEntry({user_id:getUser.user.userName,logging_level:3,
                activity_id:activities["User Logout"]
              })
              redirect()
            }}
            className="nav-link" >
            <img src="images/common/logout.png" alt="logo" style={{ width: '30px', height: '30px' }} />
            <span className="menu-title pl-3">Logout</span>
          </label>
        </li>



      </ul>
    </nav>

  )
  // }
}