import { addCommentUserStory } from "../../../UserModule/Modules/Services/addCommentUserStory";
import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoading, isLoaded } from "../action";



export async function createCalendarTask(state, dispatch, getUser, MAINTASK,MODULE,sprintDesc) {
    dispatch(isLoading());
    if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
        try {
          // eslint-disable-next-line
          var response = await API.post("manage_userstories.php", {
            crop: state.corp,
            action: "add",
            title: state.taskTitle.value,
            description: state.taskDescription.value,
            added_to: state.userSelected.value,
            added_by: getUser.empId,
            acceptanceCriteria: state.acceptanceCriteria.value,
            storyPoints: state.storySelected === "" ? '0' : state.storySelected,
            priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
            idea_id: state.epicSelected,
            "projectId": state.projectId,
            device_id: state.device_id,
            player_id: state.player_id,
            story_type: state.userSelected.from,
            main_task_id: 0,
            module_id:state.moduleId,
            target_date:state.targetDate,
            event_id:state.eventId,
            from_screen:"calendar",
            meeting_url:state.location,
  
          }, {}, false);
          if (response.status === 'True') {
            console.log("Task added success")
            if(state.moduleId!==null){
              const message = `This ${MAINTASK} added in ${MODULE} ${(state.corp).substring(0, 3).toUpperCase()} - ${sprintDesc} from calendar`
              await addCommentUserStory(dispatch,getUser,response.story_id,message,"11");
            }
            dispatch(isLoaded());
          }else{
            dispatch(isLoaded());
          }
        } catch (error) {
          Alert('error', error.message);
          dispatch(isLoaded());
        }
      }
  
      else {
        Alert("warning", "Please enter required fields ")
        dispatch(isLoaded());
      }
}

// userprofile/services/createprivateToDo.js (CREATE)


export async function createPrivateToDo(state, dispatch, getUser, MAINTASK) {
  dispatch(isLoading());
   if (state.taskTitle.value.trim() !== "" ) {
    try {
      var response = await API.post("personal_tasks.php", {
        crop: getUser.corp,
        action: "add",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        added_to: state.userSelected,
        added_by: state.userSelected,
        acceptanceCriteria: state.acceptanceCriteria.value,
        storyPoints: state.storySelected,
        priorityLevel: state.prioritySelected,
        event_id:state.eventId,
        from_screen:state.from_screen, 
        meeting_url:state.location, 
      }, {}, false);
      if (response.status === 'True') {
        dispatch(isLoaded());
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
  }
 
  else {
    Alert("warning", "Please enter required fields ")
    dispatch(isLoaded());
  }
}