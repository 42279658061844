// common/services/createTodoToProjects.js  (CREATE)

import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function createTodoToProject(state, dispatch, getUser,handleClose, pt_id,storyTitle,ADD_TO_TODO_PROJECTS, action, MAINTASK_ADD, MAINTASK) {
  // if(storyTitle === state.taskTitle.value){
  //   Alert("warning", "Please change user story title ")
  //   dispatch(isLoaded());
  // }else 
  if(state.agileProjectSelected !== ""){
  dispatch(isLoading());
   if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
    try {
      var response = await API.post("personal_tasks.php", {
        crop: getUser.corp,
        action: "assign_to_project",
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        added_by: getUser.empId,
        acceptanceCriteria: state.acceptanceCriteria.value,
        added_to: state.userSelected === "" ? (state.id.value || getUser.empId) : state.userSelected.value,
        storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
        priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
        idea_id: state.epicSelected === "" ? (state.ideaId.value || '') : state.epicSelected,
        projectId:state.agileProjectSelected,
        device_id: state.device_id,
        pt_id:pt_id,
        message: `This ${MAINTASK} added/assigned to Project`
      }, {}, false);
      if (response.status === 'True') {
        const message = action === "addUserStory" ? MAINTASK_ADD : ADD_TO_TODO_PROJECTS;
        Alert("success", message);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  }
 
  else {
    Alert("warning", "Please enter required fields ")
    dispatch(isLoaded());
  }
}else{
  Alert("warning", "Please select the Project ")
  dispatch(isLoaded());
}
} 