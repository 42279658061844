import React, { useState } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import ReportsAPI from "../../Common/AgileNetwork/ReportsAPI";
import { useSelector } from "react-redux";
import Moment from 'moment';


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function RankingForm(props) {
    console.log(props.userId);
    const classNames = useStyles();
    const getUser = useSelector(state => state.auth)
    const [isAdd, isUpdatedAdd] = useState(false);
    const today = new Date();
    const formattedDate = today.toLocaleDateString();
    const [month , setMonth] = useState(Moment(formattedDate, "DD/MM/YYYY").format("YYYY-MM-DD"));
    const categories = ["communication", "leadership", "team", "ownership", "execution"];
    const initialState = [{ communication: '', communication_reason: '' },
    { leadership: '', leadership_reason: '' },
    { team: '', team_reason: '' },
    { ownership: '', ownership_reason: '' },
    { execution: '', execution_reason: '' }]
    const [ranks, updateRanks] = useState(initialState);
    // const [selectedMonthData, setSelectedMonthData] = useState({
    //     month: 9,
    //     year: 2023,
    // });
    // const [isPickerOpen, setIsPickerOpen] = useState(false);
    console.log(ranks);
    const onEditChange = (key, value) => {
        const updatedState = ranks.map(item => {
            if (key in item) {
                return { ...item, [key]: value };
            }
            return item;
        });
        updateRanks(updatedState);
        console.log(ranks);
        return updatedState;
    }

    // const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

    const handleDateChange = (event) => {
        setMonth(event.target.value);
    };

    const onSubmit = async () => {
        const userData = { award_by: getUser.user.empId, award_to: props.userId, award_on: month }
        await ReportsAPI.post('/ranks/rankings', { data: ranks, userData }, {}, false)
            .then(result => {
                console.log("hello")
            })
            .catch(err => {
            })
    }


    const isAnyFieldEmpty = ranks.some(item =>
        Object.values(item).some(value => value === '')
    );

    if (isAnyFieldEmpty) {
        console.log('At least one value is empty');
    } else {
        console.log('All values are filled');
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-10  col-sm-12 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">Award Rankings</h5>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body" style={{ height: '400px', overflow: 'scroll' }}>
                                    <Container>
                                        <Row className="justify-content-md-center mt-4">
                                            <Col md={10}>

                                                <Row className="mb-4">
                                                    {/* <MuiPickersUtilsProvider>
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                variant="inline"
                                                                format="MM/dd/yyyy"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="Date picker inline"
                                                                value={selectedDate}
                                                                onChange={handleDateChange}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id="date-picker-dialog"
                                                                label="Date picker dialog"
                                                                format="MM/dd/yyyy"
                                                                value={selectedDate}
                                                                onChange={handleDateChange}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                    </MuiPickersUtilsProvider> */}

                                                    <Col >
                                                        <Button variant="success">Select Month: 
                                                        <input type="date" style={{ color: 'black' }} name="date" value={month} onChange={(event) => handleDateChange(event)}/></Button>
                                                    </Col>
                                                    {/* <Col className="text-end">
                                                        <span className="text-success">{month}</span>
                                                    </Col> */}
                                                </Row>
                                                {/* <div>
                                                    <MonthInput
                                                        selected={selectedMonthData}
                                                        setShowMonthPicker={setIsPickerOpen}
                                                        showMonthPicker={isPickerOpen}
                                                    />
                                                    {isPickerOpen ? (
                                                        <MonthPicker
                                                            setIsOpen={setIsPickerOpen}
                                                            selected={selectedMonthData}
                                                            onChange={setSelectedMonthData}
                                                        />
                                                    ) : null}
                                                </div> */}
                                                <table id="example" bordered className="table table-bordered">
                                                    <thead style={{ backgroundColor: '#F4FAF7' }}>
                                                        <tr>
                                                            <th style={{ width: '200px' }}>Ranking categories</th>
                                                            <th>Ranking</th>
                                                            <th>Reason</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {categories.map((category, i) => (
                                                            <tr key={category} style={{ height: '10px' }}>
                                                                <td style={{ textTransform: "capitalize", padding: '.3rem 1rem' }}>{category}</td>
                                                                <td style={{ padding: '.3rem 1rem' }}>
                                                                    {!isAdd ? <select value={ranks[i][category]} onChange={event => onEditChange(category, event.target.value)}>
                                                                        <option value="">Select</option>
                                                                        <option value='1'>1</option>
                                                                        <option value='2'>2</option>
                                                                        <option value='3'>3</option>
                                                                    </select> : ranks[i][category]}
                                                                </td>
                                                                <td style={{ padding: '.3rem 1rem' }}>
                                                                    {!isAdd ? <input type="text" value={ranks[i][`${category}_reason`]} class="form-control p-2 ml-1" id="recipient-reason" name="reason" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                                        onChange={event => onEditChange(`${category}_reason`, event.target.value)} placeholder="Please enter a valid reason" />
                                                                        : ranks[i][`${category}_reason`]}
                                                                    {/* <input type="text" onChange={event => onEditChange(`${category}_reason`, event.target.value)} placeholder="Please enter a valid reason" /> */}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div class="modal-footer">
                                    {!isAdd && <button type="button" class="btn btn-outline-success" disabled={isAnyFieldEmpty} style={{ borderRadius: '20px' }} onClick={() => isUpdatedAdd(true)}>Add</button>}
                                    {isAdd && <button type="button" class="btn btn-outline-warning" style={{ borderRadius: '20px' }} onClick={() => isUpdatedAdd(false)}>Edit</button>}
                                    {isAdd && <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={() => onSubmit()}>Submit</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    )
}