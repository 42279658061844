/*
filename: DelegateTask.js
purpose:To Delegate user story
Developers: G Naveen Kumar[G.N.K]
 */
import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as actions from './actions'
import { useSelector } from 'react-redux';
import { getUsers } from './Services/getUsers';
import { tasksReducer, initialState } from './tasksReducer';

import MainTaskChatBox from '../ChatMainTask';
import { useWebProperties } from '../webProperties';
import { delegateMainTask } from './Services/delegateTask';
import ChatWorkflow from '../ChatWorkflow';
import RootLoader from '../Loader/RootLoader';
import { updateUserstotyStaus } from '../../UserModule/Modules/Services/updateUserstotyStaus';


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function DelegateTask(props) {

    const [state, dispatch] = useReducer(tasksReducer, initialState);
    const getUser = useSelector(state => state.auth)
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [searchWord, setSearchWord] = useState('')

    const [cardInfo, setCardInfo] = useState();
    const classNames = useStyles();
    const { MODULE, MAINTASK } = useWebProperties();
    const activities= useSelector(state => state.landingReducer.userActivities)
    useEffect(() => {
        getUsers(dispatch, getUser.user, props.data.projectId);
        dispatch(actions.setModifyMainTask(props.data.taskId, props.data.title, props.data.description, props.data.ideaId, props.data.moduleId, props.data.acceptanceCriteria, props.data.storyPoints, props.data.id, props.data.priorityLevel, props.data.targetDate, props.data.device_id, props.data.player_id))
        // eslint-disable-next-line
    }, [])

    var userDetails = [];
    state.users.filter(((item) => item.id !== getUser.user.empId && item.email !== '')).map((users) => {
        return (
            users.workingStatus === "Active" ?
                userDetails.push({ 'value': users.id, 'label': `${users.name}`, 'device_id': users.device_id, player_id: users.player_id, 'from': 'user' }) : null
        );
    })

    const generateColor = () => {
        const randomColor = Math.floor(Math.random() * 16777215)
            .toString(16)
            .padStart(6, '0');
        return `#${randomColor}`;
    };

    const handleChatOpen = async (action, index, sno, userInfo) => {
        var info;
        if (index.task_type === 'sub_task') {
            setOpen({ status: true, action: 'workflow_task' });
            if (props.data.active_status === '1') {
                updateUserstotyStaus(activities,state, dispatch, getUser.user, "", state.taskId.value, state.moduleId.value, state.id.value, "", MAINTASK, 'sub_task', 'sub_task')
            }
            delegateMainTask(props.data.targetDate, state, dispatch, getUser.user, props.data.ideaId, props.data.moduleId, props.handleClose, MODULE, userInfo, props.data.task_type)
            info = {
                action: 'workflow_task', id: index.main_task_id, sno: sno, project_name: index.project_name, project_id: index.project_id, main_task_id: index.main_task_id,
                story_type: index.story_type, us_id: index.us_id, subtask_id: index.t_id
            }
        } else {
            setOpen({ status: true, action: 'maintask' });
            if (props.data.active_status === '1') {
                updateUserstotyStaus(activities,state, dispatch, getUser.user, "", state.taskId.value, state.moduleId.value, state.id.value, "", MAINTASK, 'main_task', 'main_task')
            }
            delegateMainTask(props.data.targetDate, state, dispatch, getUser.user, props.data.ideaId, props.data.moduleId, props.handleClose, MODULE, userInfo, props.data.task_type)
            info = {
                action: action, id: index.us_id, sno: sno, project_name: index.project_name, project_id: index.project_id, main_task_id: index.main_task_id,
                story_type: index.story_type, us_id: index.us_id
            }
        }
        setCardInfo(info);
    }

    const handleChatClose = () => {
        props.handleClose();
        setOpen({ status: false, index: 0 })
    };

    const handleSearch = (value) => {
        setSearchWord(value)
    };
    return (
        <div>
            {open.action !== "maintask" && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    closeAfterTransition
                    className={classNames.modal}
                    open={props.open}
                    onClose={props.handleChatClose}
                    disableBackdropClick={true}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={props.open}>
                        <div className={classNames.paper}>
                            <div className="user-modal-dialog d-flex justify-content-center">
                                <div className="modal-content col-lg-4 p-2" style={{ borderRadius: '10px' }}>
                                    <div class="modal-header" style={{ backgroundColor: 'rgb(234, 234, 234)' }}>
                                        {<h5 class="modal-title p-2">Delegate To</h5>}
                                        <div style={{ display: 'flex' }}>
                                            <input type="search" className='form-control form-control-sm'
                                                value={searchWord}
                                                onChange={(event) => handleSearch(event.target.value)}
                                                placeholder="Search with id or title"
                                            />
                                            <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                        </div>
                                    </div>
                                    {
                                        state.isLoading === true ?
                                            <RootLoader />
                                            :
                                            <div className="modal-body overflow-auto" style={{ display: 'inline-flex', flexWrap: 'wrap', maxHeight: '300px' }}>
                                                {/* eslint-disable-next-line */}
                                                {userDetails.length > 0 && (
                                                    userDetails.filter((val) => {
                                                        if (searchWord === "") {
                                                            return true
                                                        } else if (val.label.toLowerCase().includes(searchWord.toLowerCase())) {
                                                            return true
                                                        } else {
                                                            return false
                                                        }
                                                    }).map((user) => {
                                                        return <p style={{ width: 'fit-content', height: 'fit-content', padding: '0.2rem 0.2rem 1rem 0.2rem' }}>
                                                            <span onClick={(event) => { handleChatOpen("maintask", props.data.tasks, [], user) }}
                                                                style={{ backgroundColor: generateColor(), padding: '0.5rem', borderRadius: '5px', color: 'white', fontWeight: 600 }}>{user.label}</span>
                                                        </p>
                                                    })
                                                )}
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>)
            }
            {
                open.action === "maintask" ? <MainTaskChatBox open={open.status} handleClose={handleChatClose} data={cardInfo} handleModalClose={handleChatClose}
                /> : null
            }
            {
                open.action === "workflow_task" ? <ChatWorkflow open={open.status} handleClose={handleChatClose} data={cardInfo} handleModalClose={handleChatClose}
                /> : null
            }
        </div >
    );
}