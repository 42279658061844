import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addTasksComment } from "./addCommentTasks";


export async function modifySubTask(state, dispatch, getUser, mainTaskId, handleClose, SUBTASK_MODIFY, SUBTASK, SUBTASK_DESCRIPTION, ACCEPTANCE_CRITERIA) {
  dispatch(isLoading());
  if (state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "") {
    try {
      var response = await API.post("manage_tasks.php", {
        assignedBy: getUser.empId,
        subtaskId: state.subTaskId.value,
        "projectId":getUser.projectId,
        crop: getUser.corp,
        action: "modify",
        maintaskId: mainTaskId,
        title: state.taskTitle.value,
        description: state.taskDescription.value,
        empId: getUser.empId,
        acceptanceCriteria: state.acceptanceCriteria.value,
        assignedTo: state.userSelected === "" ? state.id.value : state.userSelected,
        targetDate: state.targetDate.value,
      }, {}, false);
      if (response.status === 'true') {
        // dispatch(getSubTasks(dispatch, getUser, mainTaskId))
        const message =  `${SUBTASK} is modified`; 
        addTasksComment(getUser, state.subTaskId.value, '4', message);
        Alert("success", SUBTASK_MODIFY);
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    let error = "";
    if (state.taskTitle.value === "") {
      error = `${SUBTASK} Title`;
    } else if(state.taskDescription.value === "") {
      error = SUBTASK_DESCRIPTION;
    } else {
      error = ACCEPTANCE_CRITERIA;
    } 
    Alert("warning", `Please give ${error}`);
  }
}