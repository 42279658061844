import React, { useEffect, useReducer, useState } from "react";
import ReportsAPI from "../../Common/AgileNetwork/ReportsAPI";
import { DateRangePicker } from 'react-date-range';
import { startOfWeek, endOfWeek } from 'date-fns';
import { Modal } from 'react-bootstrap';
import AdminSideBar from "../Utility/SideNav";
import Header from "../../Common/TopNav";
import { useSelector } from "react-redux";
import { FaCheck, FaTimes } from 'react-icons/fa';
import './ranks.css';
import { useWebProperties } from "../../Common/webProperties";
import moment from 'moment';
import Select from 'react-select'
import API from '../../Common/Network/API';
import { removeDuplicatesFromString } from '../../Common/commonUsage';
import { getUsers } from "./Services/getUsers";
import { rankReducer, initialState } from "./rankReducer";
import RootLoader from "../../Common/Loader/RootLoader";
import $ from 'jquery';
import { sendRankMessage } from "./Services/sendRankMessage";

export default function RankingForm(props) {
    const getUser = useSelector(state => state.auth);
    const [state, dispatch] = useReducer(rankReducer, initialState)
    const [users, setUsers] = useState([]);
    const { SELECT_DATE_RANGE, DATE } = useWebProperties();
    const [show, setShow] = useState(false);
    const [filter, setFilter] = useState([
        {
            startDate: startOfWeek(new Date(), { weekStartsOn: 0 }),
            endDate: endOfWeek(new Date(), { weekEndsOn: 7 }),
            key: 'selection'
        }
    ])
    const [groupDetails, setGroupDetails] = useState([])
    const [selectedGroupDetails, setSelectedGroupDetails] = useState([]);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        if (state.users.length > 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                    "ordering": false
                })
            })
        }
    }, [state.users])

    useEffect(() => {
        getUsers(dispatch, filter);
        getAdminGroupDetails(getUser.user)
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        setUsers(state.users)
        // eslint-disable-next-line
    }, [state.users.length]);

    async function getAdminGroupDetails(getUser) {
        try {
            var response = await API.post("group_chat.php", {
                action: 'getAdminGroupDetails',
                created_by: getUser.userName,
                projectId: getUser.projectId,
                "userType": getUser.role,
                "empId": getUser.empId,
            }, {}, false);
            if (response.status === 'True') {
                setGroupDetails(response.data);
            }
            else {
                setGroupDetails([])
            }
        } catch (error) {
            console.log('error', error.message);
        }
    }

    const handleSelectedGroups = (selectedOption) => {
        if (selectedOption.value === 'All') {
            getUsers(dispatch, filter);
        } else {
            getUsers(dispatch, filter, selectedOption.emails);
        }
    }

    const onChangeUserRanks = (key, index, value) => {
        const updatedData = users.map((item, i) => i === index ? { ...item, [key]: value } : item);
        if (key !== 'feedback') {
            const result = calculatePercentage(updatedData[index])
            const updateWithRanksPercent = updatedData.map((item, i) => i === index ? { ...item, percentage: Math.round(result) } : item);
            setUsers(updateWithRanksPercent);
        } else if (key === 'status') {
            const updateWithRanksPercent = updatedData.map((item, i) => i === index ? { ...item, status: 1 } : item);
            setUsers(updateWithRanksPercent);
        } else {
            setUsers(updatedData);
        }
        return true;
    }

    // Define the debounce function
    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    // Define the debounced search function
    const debouncedSearch = debounce((key, index, value) => {
        if (value.length > 0) {
            onChangeUserRanks(key, index, value)
        }
    }, 1000); // 1000 milliseconds debounce time

    // Call the debounced search function when handling search
    const handleSearch = (key, index, value) => {
        debouncedSearch(key, index, value);
    };

    const clearUserRanks = (userId, index) => {
        clearInput(index);
        const initialRanks = { communication: 0, leadership: 0, team: 0, ownership: 0, execution: 0, percentage: 0, feedback: '', status: 0 };
        const updatedData = users.map((item, i) => (item.emp_id === userId && i === index) ? { ...item, ...initialRanks } : item);
        setUsers(updatedData);
        return true;
    }
    function clearInput(index) {
        const inputElement = document.getElementsByName('feedback')[index];
        inputElement.value = ''; // Clear the input field
    }

    /* This Existing Calculation is on hold and modified with new Xls calculation as below.
    function valueToPercentage(value) {
        switch (value) {
            case 1:
                return 100;
            case 2:
                return 66.67;
            case 3:
                return 33.33;
            case 4:
                return 0;
            default:
                return 0; // For safety in case of invalid input
        }
    }

    function calculatePercentage(userranks) {
        const { communication, leadership, team, ownership, execution } = userranks;
        const values = [communication, leadership, team, ownership, execution];
        // Convert each value to its corresponding percentage
        const percentages = values.map(valueToPercentage);

        // Calculate the average percentage
        const averagePercentage = percentages.reduce((sum, p) => sum + p, 0) / percentages.length;
        return averagePercentage;
    } */

    function calculatePercentage(userranks) {
        const { communication, leadership, team, ownership, execution } = userranks;
        const values = [communication, leadership, team, ownership, execution];
        const rank = values.filter(item => item !== 0).reduce((acc, val) => acc * val, 1);
        if (rank >= 1 && rank <= 1024) {
          const result = ((4 - Math.pow(rank, 1 / 5)) * 1 / 3) * 100;
          return Math.round(result * 100) / 100;
        } else {
          return 0;
        }
      }

    const onSubmit = async (userRanks, index) => {
        onChangeUserRanks('status', index, 1);
        let dateFilter = [{
            "startDate": moment(filter[0].startDate).format("YYYY-MM-DD"),
            "endDate": moment(filter[0].endDate).format("YYYY-MM-DD"),
            "key": "selection"
        }]
        const data = { award_by: getUser.user.empId, ...userRanks };
        await ReportsAPI.post('/ranks/rankings', { data, filter: dateFilter }, {}, false)
            .then(result => {
                sendRankMessage(userRanks, getUser)
            })
            .catch(err => {
                onChangeUserRanks('status', index, 0);
            })
    }

    function isAnyFieldEmpty(users) {
        return Object.keys(users).every(key => {
            if (key === 'status') return true;
            return users[key] !== 0 && users[key] !== '';
        });
    }

    const handleDateSearch = () => {
        if (selectedGroupDetails.value === 'All') {
            getUsers(dispatch, filter);
        } else {
            getUsers(dispatch, filter, selectedGroupDetails.emails);
        }
        handleClose();
    }

    const updateUserRanks = (rankKey, i, rankName) => {
        return (
            <select className="selectBox" value={rankName} onChange={event => onChangeUserRanks(rankKey, i, Number(event.target.value))}>
                <option value={0}>Select</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
            </select>)
    }
    return (
        <div className="container-scroller">
            <Header />
            <div className="container-fluid page-body-wrapper">
                <AdminSideBar />
                <div className="main-panel">
                    <div className="mt-2 p-2">
                        <div className="d-flex justify-content-between row">
                            <div className="d-flex" >
                                {
                                    <button className="btn btn-success p-2 border" style={{ marginLeft: 10, height: 'fit-content' }} onClick={handleShow}>{SELECT_DATE_RANGE}</button>
                                }
                                {
                                    <div style={{ marginTop: 5 }}>
                                        <text style={{ fontSize: 14, fontWeight: 'bold', paddingLeft: 10 }} className=' text-success'> {DATE}: {(moment(filter[0].startDate).format('ll'))} - {(moment(filter[0].endDate).format('ll'))} </text>

                                    </div>
                                }
                            </div>
                            <div className="d-flex col-lg-4 col-sm-6" >
                                <label className='mr-2 mb-0 mt-2' htmlFor='groupSelect'>Group:</label>
                                <Select
                                    className="form-control"
                                    placeholder="Select Group"
                                    options={[
                                        { 'value': 'All', 'label': 'All' },
                                        ...(groupDetails?.map(group => {
                                            return {
                                                'value': group.id,
                                                'label': `${group.group_name}-[${removeDuplicatesFromString(group.members_name)}]`,
                                                'emails': group.members_email
                                            };
                                        }))
                                    ]}
                                    onChange={(selectedOption) => {
                                        handleSelectedGroups(selectedOption)
                                        setSelectedGroupDetails(selectedOption)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 " >
                            <Modal style={{ textAlign: 'center' }} size="xl" show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <DateRangePicker
                                        onChange={item => setFilter([item.selection])}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={filter}
                                        direction="horizontal"
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-success" type="button" onClick={() => {
                                        handleDateSearch();
                                    }}>Search</button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        {state.isLoading ? <RootLoader /> :
                            <div style={{ overflowX: 'scroll', marginTop: '1rem' }}>
                                <table
                                    id="example" className="table table-striped table-bordered rwd-table tableUserRanks"
                                    search="true">
                                    <thead style={{ backgroundColor: '#F4FAF7' }}>
                                        <tr>
                                            <th>User Name</th>
                                            <th>Communication</th>
                                            <th>Leadership</th>
                                            <th>Team</th>
                                            <th>Ownership</th>
                                            <th>Execution</th>
                                            <th>Percentage(%)</th>
                                            <th>Feedback</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map(({ email, communication, leadership, team, ownership, execution, status, percentage, feedback }, i) => (
                                            <tr key={i}>
                                                <td>{email}</td>
                                                <td>{status === 1 ? communication : updateUserRanks("communication", i, communication)}</td>
                                                <td>{status === 1 ? leadership : updateUserRanks("leadership", i, leadership)}</td>
                                                <td>{status === 1 ? team : updateUserRanks("team", i, team)}</td>
                                                <td>{status === 1 ? ownership : updateUserRanks("ownership", i, ownership)}</td>
                                                <td>{status === 1 ? execution : updateUserRanks("execution", i, execution)}</td>
                                                <td style={{ color: 'blue', fontWeight: '600', textAlign: 'center' }}>{percentage} %</td>
                                                {/* <td>{calculatePercentage(user)}</td> */}
                                                <td style={{ width: '200px' }}>
                                                    {/* {!isAdd ? <input type="text" value={feedback} id="recipient-reason" name="reason" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                    onChange={event => onChangeUserRanks('feedback', i, event.target.value)} placeholder="Please enter a valid reason" />
                                    : users[i][`${feedback}`]} */}
                                                    <div className="custom-input-container">
                                                        {status === 1 && <input type="text" value={feedback} name="feedback" className="custom-input" placeholder="Please enter your feedback" disabled={status === 1} />}
                                                        {status === 0 && <input type="text" name="feedback" className="custom-input" onChange={event => handleSearch('feedback', i, event.target.value)} placeholder="Please enter your feedback" disabled={status === 1} />}
                                                        {status === 0 && isAnyFieldEmpty(users[i]) && (
                                                            < div className="icons-container">
                                                                | <FaCheck className="icon success-icon" onClick={() => onSubmit(users[i], i)} />
                                                                <FaTimes className="icon error-icon" onClick={() => clearUserRanks(users[i].emp_id, i)} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div >
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}